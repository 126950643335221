import React, { useContext } from 'react'
import { frabchisingSentData } from '../../assets/dummyData/SentEmail';
import { Context } from '../../context/Products';

export const FrabchisingSent = ({ sendAnotherEmail }) => {
  const [{ lang }] = useContext(Context);
  return (
    <div class="msg-send" id="contact_form_send_success">
        <span class="ttl">
          {frabchisingSentData[lang].title}
        </span>
        <p>
          {frabchisingSentData[lang].subTitle}
        </p>
        <button onClick={() => sendAnotherEmail(true)} class="btn btn-primary">{frabchisingSentData[lang].button}</button>
    </div>
  )
}
