import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';

import Products from "./context/Products";
import Footer from './components/Footer/Footer';
import Loader from './components/Loader/Loader';
import NavBar from './components/NavBar/NavBar';
import AboutPage from './pages/AboutPage';
import CareerPage from './pages/CareerPage';
import ContactPage from './pages/ContactPage';
import FaqPage from './pages/FaqPage';
import FranchisingPage from './pages/FranchisingPage';
import HomePage from './pages/HomePage';
import PageNotFound from './pages/PageNotFound';
import ProductsPage from './pages/ProductsPage';
import StoresPage from './pages/StoresPage';
import CaptainStoryPage from './pages/CaptainStoryPage';
import PopupNewsletter from './components/PopupNewsletter/PopupNewsletter';
import PersonalDataProcessingPage from './pages/PersonalDataProcessingPage';
import ConfirmEmail from './components/ConfirmEmail/ConfirmEmail';
// import "./assets/dist/aos.css";
// import "./assets/dist/aos.js";

function App() {
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    setTimeout(() => {
      setLoading(true)
    }, 1140);
  }, [location.pathname]);

  return (
    <div className="web-en">
      <Products>
        <PopupNewsletter />
        <Loader showLoader={loading} />
        <NavBar activeClass={location.pathname} />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/captain-story" element={<CaptainStoryPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/stores" element={<StoresPage />} />
          <Route path="/career" element={<CareerPage />} />
          <Route path="/faq" element={<FaqPage />} />
          <Route path="/confirm-email" element={<ConfirmEmail />} />
          <Route path="/personal-data-processing" element={<PersonalDataProcessingPage />} />
          <Route path="/franchising" element={<FranchisingPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer activeClass={location.pathname} />
      </Products>
    </div>
  );
}

export default App;
