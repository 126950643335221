import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { pageNotFoundData } from '../assets/dummyData/pageNotFoundData';
import Chest from "../assets/img/chest-404.png";
import Helmet from '../components/Helmet';
import { Context } from '../context/Products';

const PageNotFound = () => {
    const [{ lang }] = useContext(Context);
    return (
        <div id="page-404" className="page fade-onload">
            <Helmet
                title="Captain Candy"
                metaDesc="You can find our stores in various cities in Europe. But watch out! Not every wood interior store is a guarantee of Captain Candy quality. Always look for only the original Captain Candy stores, which are a safe bet. You can find a list of stores on our website."
            />
            <div className="container-custom">
                <div className="visible-xs">
                    <img src={Chest} alt="404" className="chest" />
                </div>
                <div className="hidden-xs">
                    <div className="chest"></div>
                </div>
                <div className="content">
                    <h1 className="page-heading fade-onload">
                        {pageNotFoundData[lang].title}
                    </h1>
                    <p>
                        {pageNotFoundData[lang].description}
                    </p>
                    
                    <div className="btn-wrap" style={{ marginTop: "60px" }}>
                        <Link to="/" className="btn btn-primary">
                            {pageNotFoundData[lang].button}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound;
