import React, { useContext, useState } from 'react';
import About from '../components/About/About';
import AnimationEffects from '../assets/Animations/hooks/AnimationEffects';
import NewLetter from '../components/NewLetter/NewLetter';
import { AboutData } from '../assets/dummyData/About';
import { Context } from '../context/Products';
import { NewsLetterContent } from '../assets/dummyData/NewsLetterData';
import Helmet from '../components/Helmet';
import logo from "../assets/img/prodejny_map.png"
import ModalEmailSuccess from '../components/ModalEmailSuccess/ModalEmailSuccess';

const AboutPage = () => {
    const [{ lang }] = useContext(Context);
    const { singleRefs } = AnimationEffects(['NewLetter'], [] ,'opacity', 0.2 , 0.12);
  const [shoeModalEmail, setShoeModalEmail] = useState(false);

    const modalEmail = () => {
        setShoeModalEmail(true);
        const body = document.body;
        body.classList.add("modal-open");
        setTimeout(() => {
            setShoeModalEmail(false);
        }, 500);
    }

    return (
        <>
            <Helmet
                title="About | Captain Candy"
                metaDesc="Abbiamo ottimi dolci per te. Visita il nostro e-shop e acquista ottimi dolci del marchio ceco. Crea subito il tuo mix."
                opengraphImage={logo}
                twitterImage={logo}
            />
            <div id="page-about">
                <About aboutData={AboutData[lang]} footerText={AboutData.footerText[lang]} />
            </div>
            <div className="opacity-0" ref={singleRefs.elements.NewLetter.ref}>
                <NewLetter data={NewsLetterContent[lang]} openModal={modalEmail} />
            </div>
            <ModalEmailSuccess open={shoeModalEmail} />
        </>
    )
}

export default AboutPage;