import { Fragment } from "react";
import { Link } from "react-router-dom";
const toTop = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
};

const franchisingData = {
    en: {
        title: "Captain Candy Franchising",
        history: [
            {
                question: "Captain Candy concept caught your eye?",
                answer: "Captain Candy is a successful and proven concept operating a network of candy shops. Our shops are so unique that in many cases they have been the subject of illegal copying. However, there is only one original Captain Candy. In our stores, we do not only focus on a wide selection of the most delicious candies, but the overall customer experience is just as important to us.",
            },
            {
                question: "Would you like to share the joy of discovery and the enthusiasm of returning to youth in your surroundings?",
                answer: <Fragment>With a Captain Candy franchise, you can offer your customers the joy of discovery and the
                excitement of returning to their childhood. <br/>For more information, please fill out the questionnaire below.</Fragment>,
            },
        ],
        list: [
            {
                headingtitle: "Why work with us as a franchising partner?",
                headingLi: [
                    'Our concept boasts its own unique style',
                    "We offer quality of an international calibre",
                    "We’re constantly developing and improving",
                    "We’re a tried and tested European brand",
                ],
            },
            {
                headingtitle: "What we offer franchising partners?",
                headingLi: [
                    "The security of working for an international brand",
                    "Tried and tested sales know-how, which is constantly being innovated",
                    "Well-thought out, tried and tested and ready-made solutions",
                    "Help not only before opening your store and in the early stages, but also any time further down the line",
                    "The opportunity for personal development and self-realisation in getting your business off the ground",
                    "The chance to contribute to the development and growth of the brand",
                    "Stocking up with products and the necessary equipment to operate a franchise",
                    "System of regular checks and consultations",
                    "Long-term income and appreciation of your investment",
                    "Mutual agreement on a specific form of cooperation",
                ]
            }
        ],
        listParagraphsTitle: "How to get a Captain Candy franchise",
        listParagraphs:  [
            "We see franchising as a longstanding and mutually beneficial relationship, a bit like accepting another person into our business family. We therefore take great care to ensure our selection process is fair, thorough and prudent.",
            " For this reason anyone interested in applying is asked to complete a QUESTIONNAIRE to share preliminary (and non-binding) information with us, which allows us to ascertain whether our future collaboration is built on sufficiently solid foundations and has real prospects for success. We’ll get in touch after reading through your responses.",
            <Fragment><i> By sending your responses, you are automatically entered onto our database of people interested in working with us. We treat any information contained in the questionnaire in accordance with the General Data Protection Regulation (GDPR) and use it exclusively for the purpose of potential collaboration with you.</i></Fragment>,
        ],
        questionTitle: "QUESTIONNAIRE",
        inputs: [
            {
                id: "contactform_fullName",
                name: "contactform_fullName",
                label: "Name and surname*",
            },
            {
                id: "contactform_email",
                name: "contactform_email",
                label: "E-mail*",
            },
            {
                id: "contactform_phone",
                name: "contactform_phone",
                label: "Telephone number*",
                class: "inputphone",
                style: { display: "inline-block" },
            },
            {
                id: "contactform_text_1",
                name: "contactform_text_1",
                label: "Why do you want to work with Captain Candy?",
                textarea: true,
            },
            {
                id: "contactform_text_2",
                name: "contactform_text_2",
                label: "How did you find out about Captain Candy?",
                textarea: true,
            },
            {
                id: "contactform_text_3",
                name: "contactform_text_3",
                label: "What is your current professional experience?*",
                textarea: true,
            },
            {
                id: "file_upload",
                name: "file_upload",
                label: "Please attach a detailed CV*",
                button: "Browse"
            },
            {
                id: "contactform_text_4",
                name: "contactform_text_4",
                label: "Do you have any experience running a small business? If o what?*",
                textarea: true,
            },
            {
                id: "contactform_text_5",
                name: "contactform_text_5",
                label: "Do you work for any other brands?*",
                textarea: true,
            },
            {
                id: "contactform_text_6",
                name: "contactform_text_6",
                label: "Do you have any other commitments or things that would restrict you from this work? Have you signed a competition clause preventing you from working with a particular brand or concept?*",
                textarea: true,
            },
            {
                id: "contactform_text_7",
                name: "contactform_text_7",
                label: "Do you or have you (or anyone close to you) previously run a franchise?",
                textarea: true,
            },
            {
                id: "contactform_text_8",
                name: "contactform_text_8",
                label: "If you’ve never run your own business before, what is your motivation for starting one now?",
                textarea: true,
            },
            {
                id: "contactform_text_9",
                name: "contactform_text_9",
                label: "Do you have a clean criminal record and are you prepared to supply proof of this?*",
                textarea: true,
            },
            {
                id: "contactform_text_10",
                name: "contactform_text_10",
                label: "To what degree can you take part in the day to day running of the store?*",
                textarea: true,
            },
            {
                id: "contactform_text_11",
                name: "contactform_text_11",
                label: "What makes you think that Captain Candy will be a success in your city, country or region?",
                textarea: true,
            },
            {
                id: "contactform_text_12",
                name: "contactform_text_12",
                label: "Where do you want to open your premises? What town, big city, region or country interest you in terms of running Captain Candy franchising stores?*",
                textarea: true,
            },
            {
                id: "contactform_text_13",
                name: "contactform_text_13",
                label: "Do you have your own/rented sales spaces to run your Captain Candy store? How big is it, what are the floor plans and where is it? How long will you have it for?",
                textarea: true,
            },
            {
                id: "contactform_text_14",
                name: "contactform_text_14",
                label: "What level of funds are you prepared to invest in the Captain Candy project? How do you plan to finance construction, furnishings and running costs during the start-up phase? How much can you source from your own funds?*",
                textarea: true,
            },
        ],
        link: <Fragment>“I consent to the  <Link  onClick={() => toTop()} to="/personal-data-processing" className="link">processing of my personal data</Link> and the responses given in the questionnaire.</Fragment>,
        button: "Send"
    },
    it: {
        title: "Franchising Captain Candy",
        history: [
            {
                question: "Sei interessato/a al concetto di Captain Candy?",
                answer: "Captain Candy è un concept riconosciuto che gestisce un network di negozi di caramelle. I nostri negozi sono così unici che, in molti casi, sono stati oggetto di contraffazione. Tuttavia, c’è un solo Captain Candy originale. Nei nostri negozi, non solo ci focalizzaimo su di un’ampia selezione di deliziosi dolciumi, ma anche l’esperienza dei nostri clienti è per noi fondamentale.",
            },
            {
                question: "Vorresti vivere la gioia della scoperta e l'emozione di tornare giovane e trasmettere queste sensazioni anche a chi ti sta intorno?",
                answer: <Fragment>Con il franchise di Captain Candy potrai offrire alla tua clientela la gioia della scoperta e l’eccitazione
                di un ritorno all’infanzia. <br/>
                Per maggiori informazioni, compila il questionario in calce.</Fragment>,
            },
        ],
        list: [
            {
                headingtitle: "Perché lavorare con noi come partner di franchising?",
                headingLi: [
                    'Il nostro concetto ha il suo stile unico',
                    "Offriamo qualità a livello internazionale",
                    "Il concetto viene costantemente sviluppato e migliorato",
                    "Siamo un marchio europeo affidabile",
                ],
            },
            {
                headingtitle: "Che cosa offriamo ai nostri partner di franchising?",
                headingLi: [
                    "La certezza di un'impresa di successo con un marchio internazionale",
                    "Know-how di vendita certificato, che innoviamo continuamente",
                    'Soluzione ideata, testata, collaudata e pronta „chiavi in mano“',
                    "Assistenza non solo prima dell'apertura e nei primi giorni, ma anche in qualsiasi momento successivo",
                    "Opportunità di sviluppo personale e autorealizzazione aprendo un proprio negozio",
                    "Opportunità di partecipare allo sviluppo e alla crescita del marchio",
                    "Fornitura di prodotti e attrezzature necessarie per le attività di franchising",
                    "Sistema di ispezioni regolari con consulenza",
                    "Proventi a lungo termine e ritorno dell'investimento",
                    'Accordo su una forma specifica di cooperazione',
                ]
            }
        ],
        listParagraphsTitle: "Come ottenere il franchising Captain Candy",
        listParagraphs:  [
            "Intendiamo il franchising come una relazione a lungo termine e reciprocamente vantaggiosa, come accogliere un altro membro nella nostra famiglia aziendale. Pertanto, selezioniamo i partner in modo equo, accuratamente e minuziosamente.",
            "Con ogni soggetto interessato che si rivolge a noi avviamo uno scambio preliminare (e non vincolante) di informazioni usando un QUESTIONARIO, al fine di assicurarci che la nostra eventuale cooperazione abbia una base sufficiente e una prospettiva reale. Elaboriamo le vostre risposte e poi vi contattiamo.",
            <Fragment><i>Se si invia una risposta si viene automaticamente inclusi nel database dei soggetti interessati alla cooperazione. Le informazioni fornite nel questionario sono trattate secondo il regolamento generale sulla protezione dei dati (GDPR) e le usiamo solo allo scopo di instaurare una cooperazione.</i></Fragment>,
        ],
        questionTitle: "QUESTIONARIO",
        inputs: [
            {
                id: "contactform_fullName",
                name: "contactform_fullName",
                label: "Nome e cognome*",
            },
            {
                id: "contactform_email",
                name: "contactform_email",
                label: "E-mail*",
            },
            {
                id: "contactform_phone",
                name: "contactform_phone",
                label: "Telefono*",
                class: "inputphone",
                style: { display: "inline-block" },
            },
            {
                id: "contactform_text_1",
                name: "contactform_text_1",
                label: "Perché vuoi collaborare proprio con Captain Candy?",
                textarea: true,
            },
            {
                id: "contactform_text_2",
                name: "contactform_text_2",
                label: "Come hai saputo di Captain Candy?",
                textarea: true,
            },
            {
                id: "contactform_text_3",
                name: "contactform_text_3",
                label: "Quali sono le tue esperienze di lavoro?*",
                textarea: true,
            },
            {
                id: "file_upload",
                name: "file_upload",
                label: "Allega curriculum vitae - CV*",
                button: "Sfoglia"
            },
            {
                id: "contactform_text_4",
                name: "contactform_text_4",
                label: "Hai esperienza con la gestione di un negozio di vendita al dettaglio? In caso affermativo, che tipo di esperienza*",
                textarea: true,
            },
            {
                id: "contactform_text_5",
                name: "contactform_text_5",
                label: "Lavori ancora per altri marchi?*",
                textarea: true,
            },
            {
                id: "contactform_text_6",
                name: "contactform_text_6",
                label: "Hai obblighi, restrizioni o divieti di concorrenza rispetto a un marchio o concetto?*",
                textarea: true,
            },
            {
                id: "contactform_text_7",
                name: "contactform_text_7",
                label: "Gestisci o hai gestito (tu o qualche tuo caro) un franchising?",
                textarea: true,
            },
            {
                id: "contactform_text_8",
                name: "contactform_text_8",
                label: "Se non hai mai svolto attività imprenditoriale, che cosa ti spinge ad iniziare?",
                textarea: true,
            },
            {
                id: "contactform_text_9",
                name: "contactform_text_9",
                label: "Hai la fedina penale pulita e sei disposto/a a provarlo?*",
                textarea: true,
            },
            {
                id: "contactform_text_10",
                name: "contactform_text_10",
                label: "In che misura puoi dedicarti attivamente alla gestione quotidiana di un negozio?*",
                textarea: true,
            },
            {
                id: "contactform_text_11",
                name: "contactform_text_11",
                label: "Perché pensi che Captain Candy possa avere successo nella tua città, Stato o regione?",
                textarea: true,
            },
            {
                id: "contactform_text_12",
                name: "contactform_text_12",
                label: "Dove desideri aprire il negozio? In quale/i città, metropoli, regione/i o Stato/i desidereresti aprire i tuoi negozi di franchising Captain Candy?*",
                textarea: true,
            },
            {
                id: "contactform_text_13",
                name: "contactform_text_13",
                label: "Disponi di spazi di vendita propri/locati per il negozio Captain Candy? Quanto sono grandi, quale è la pianta degli spazi e dove sono situati? Per quanto tempo saranno disponibili?",
                textarea: true,
            },
            {
                id: "contactform_text_14",
                name: "contactform_text_14",
                label: "Di quanti fondi disponi per il progetto Captain Candy? In che modo pensi di finanziare la costruzione, l'arredamento e la gestione durante il lancio del negozio? Quale importo sei in grado di fornire da tue risorse?*",
                textarea: true,
            },
        ],
        link: <Fragment>Acconsento al  <Link  onClick={() => toTop()} to="/personal-data-processing" className="link">trattamento dei dati personali </Link> e  delle risposte fornite nel questionario</Fragment>,
        button: "Invia"
    },
};

export {
    franchisingData,
}