import React from "react";
import hpBox from "../img/hp_box_1-min.jpg";
import hpBox1 from "../img/hp_box_2-min.jpg";
import hpBox2 from "../img/hp_box_3-min.jpg";
import hpBox3 from "../img/hp_box_4-min.jpg";

const boxes = {
    title: {
        en: "From Captain Candy’s board",
        it: "A Bordo del Captain Candy",
    },
    en: [
        {
            img: hpBox,
            title: <React.Fragment> Can you smell the sweetness in the air?</React.Fragment>,
            description: "Temptation at its best. Discover this world of sweets with us.",
            link: "More information",
            href: "/products",
            longer: true,
        },
        {
            img: hpBox1,
            title: <React.Fragment>All the places we anchor</React.Fragment>,
            description: "You can now find us in four places in Prague!",
            link: "More information",
            href: "/stores"
        },
        {
            img: hpBox2,
            title: <React.Fragment>The end of sour looks!</React.Fragment>,
            description: "Do people give you sour looks? Try offering them our sour jelly beans!",
            link: "More information",
            href: "/captain-story",
            longer: true,
        },
        {
            img: hpBox3,
            title: <React.Fragment>Find out more about what we do</React.Fragment>,
            description: "Discover the journey of a Czech company that brings joy from all over the world.",
            link: "More information",
            href: "/about"
        },
    ],
    it: [
        {
            img: hpBox,
            title: "Puoi sentire il profumo della dolcezza nell’aria?",
            description: "La tentazione al suo massimo. Scopri questo mondo di dolciumi con noi.",
            link: "Maggiori informazioni",
            href: "/products",
            longer: true,
        },
        {
            img: hpBox1,
            title: "Dove ancoriamo",
            description: "A Praga ci trovi in quattro luoghi diversi!",
            link: "Maggiori informazioni",
            href: "/stores"
        },
        {
            img: hpBox2,
            title: "Stop agli sguardi acidi!",
            description: "Ti guardano in modo acido? Prova ad offrire loro i nostri gelèe acidi!",
            link: "Maggiori informazioni",
            href: "/captain-story",
            longer: true,
        },
        {
            img: hpBox3,
            title: "Scopri di più su ciò che facciamo",
            description: "Scopri la rotta percorsa da quest'azienda ceca che importa gioia da tutto il mondo.",
            link: "Maggiori informazioni",
            href: "/about"
        },
    ],
};

const HpMapData = {
    en: {
        title: "Our store in Amsterdam",
        button: {
            name: "More information",
        }
    },
    it: {
        title: "I nostri negozi a Venezia",
        button: {
            name: "Maggiori informazioni",
        }
    },
};
    
export { boxes, HpMapData };
    