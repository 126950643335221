import React, { useContext } from 'react';
import { useState } from 'react';
import AnimationEffects from '../assets/Animations/hooks/AnimationEffects';
import { NewsLetterContent } from '../assets/dummyData/NewsLetterData';
import { productsItems } from '../assets/dummyData/ProductsPageData';
import ModalEmailSuccess from '../components/ModalEmailSuccess/ModalEmailSuccess';
import NewLetter from '../components/NewLetter/NewLetter';
import Products from '../components/Products/Products';
import { Context } from '../context/Products';
import Helmet from "../components/Helmet";
// import Logo from "../assets/img/products/products-min.png";

const ProductsPage = () => {
    const [shoeModalEmail, setShoeModalEmail] = useState(false);
    const [{ lang }] = useContext(Context);
    const { singleRefs } = AnimationEffects(['NewLetter'], [] ,'fadeInOnce', 0.4, 0.12);

    const modalEmail = () => {
        setShoeModalEmail(true);
        const body = document.body;
        body.classList.add("modal-open");
        setTimeout(() => {
            setShoeModalEmail(false);
        }, 500);
    }
    const title = "Products | Captain Candy"
    const desc = "There are thousands of tastes in the world. Choose your favorite from us. We do everything to offer you the best quality candies from the best suppliers. To satisfy even the most demanding sweet tooth."
    
    return (
        <>
            <div id="page-products" className="page fade-onload">
                <Helmet
                    title={title}
                    metaDesc={desc}
                />
                <Products
                    productsItems={productsItems[lang]}
                    title={productsItems.headTitle[lang]}
                    subTitle={productsItems.headSubTitle[lang]}
                    stores={productsItems.stores[lang]}
                />
                <div className="opacity-0" ref={singleRefs.elements.NewLetter.ref}>
                    <NewLetter data={NewsLetterContent[lang]} openModal={modalEmail} />
                </div>
            </div>
            <ModalEmailSuccess open={shoeModalEmail} />
        </>
    )
}

export default ProductsPage;
