import { Fragment } from "react";
import { Link } from "react-router-dom";

const faqData = {
    title: {
        en: "Frequently asked questions",
        it: "Domande frequenti",
    },
    en: [
    	{
            id: "1",
            title: " Where do the sweets in Captain Candy’s stores come from?", 
            content: "Captain Candy’s ships travel all over Europe (from Spain to Germany, from Sweden to Italy) to the very best suppliers for candy. All you have to do is look at the barrel or the box and that will tell you where the particular candy has sailed from."
        },
        {
            id: "2",
            title: " What is the shelf life of the candy?", 
            content: "As we pirates say, “You can find the answer to everything in a barrel”. In this case not actually in the barrel, but on the barrel. The candy’s shelf life is written on each of them.",
        },
        {
            id: "3",
            title: "How should the candy be stored after it has been bought?", 
            content: "In the same way as supplies on pirate expeditions - if possible in dry and cold conditions",
        },
        {
            id: "4",
            title: "Is it okay that the candy in the stores lies in open barrels?", 
            content: "Although pirates often lived outside the law, everything’s all right here. It’s called “free sale” and doesn’t break hygiene rules in any way. It’s like when you put bread in a bag in a port’s “super” market…",
        },
        {
            id: "5",
            title: "Where does the Captain Candy company come from?", 
            content: "The first Captain Candy store was opened in Prague and after this success we decided to expand further. You can currently find us in several countries and we are planning more!",
        },
        {
            id: "6",
            title: "Why can’t we try the candy in the barrels?", 
            content: "Unfortunately, it’s not possible for hygiene reasons. But don’t be afraid to ask our crews in the stores – they will be happy to help you find the exact taste you are looking for.",
        },
        {
            id: "7",
            title: "Is it hygienic to put tongs on barrels? ", 
            content: "The old pirate rule states that “tongs belong on a barrel”. What’s more, here too we respect the law which doesn’t see any problem with this.",
        },
        // {
        //     id: "8",
        //     title: "How many stores does Captain Candy have?", 
        //     content: "So far we have dropped anchor in four places in Prague and we have two harbours each in Dubrovnik in Croatia and in Venice in Italy. And we are planning more expeditions!",
        // },
        {
            id: "9",
            title: "Does the Captain Candy concept also work as a franchise?", 
            content: <Fragment>Yes, the Captain Candy concept successfully operates several franchise stores. If you are interested in cooperation, please contact us via the form in the <Link to="/franchising">&quot;Franchising&quot;</Link>  section.</Fragment> ,
        },
        {
            id: "10",
            title: "Are the barrels really completely full?", 
            content: "Pirates must have some secrets. And that’s why we’ll leave it to you to guess.",
        },
        {
            id: "11",
            title: "Which is the most popular candy?", 
            content: "Not even the famous sorceress from the Island of Crystal Balls could answer that. Everyone chooses whatever suits their taste and the best-selling candy often changes. If you look at the barrels in the stores you will find out more.",
        },
        {
            id: "12",
            title: "What are the stores’ opening hours?", 
            content: <Fragment>From Monday to Friday we take in every traveller from 10 in the morning till 11 at night. At weekends between 10 o’clock in the morning and midnight. You can look in detail here. <Link to="/stores">here</Link>.</Fragment>,
        },
        {
            id: "13",
            title: " Why isn’t the price of the candy written on each barrel?", 
            content: " Because there’s no need. ;) The price per 100 g is uniform for the entire selection and you will always find it written in the store.",
        },
    ],
    it: [
    	{
            id: "1",
            title: "Da dove provengono le caramelle dei negozi Captain Candy?", 
            content: "Le navi di Captain Candy navigano per acquistare caramelle dai migliori fornitori in tutta Europa (dalla Spagna alla Germania, dalla Svezia all'Italia). Basta dare un'occhiata a una botte o una scatola per scoprire da dove proviene una determinata caramella."
        },
    	{
            id: "2",
            title: "Qual è la durata minima di conservazione delle caramelle?", 
            content: 'Come diciamo noi pirati "la risposta a tutto è nella botte". In questo caso non nella botte, ma sulla botte. Su ognuna di esse è indicata la durata minima di conservazione delle caramelle.'
        },
    	{
            id: "3",
            title: "Come si devono conservare le caramelle dopo l'acquisto?", 
            content: "Come le scorte delle spedizioni dei pirati: preferibilmente in un luogo asciutto e fresco."
        },
    	{
            id: "4",
            title: "Il fatto che le caramelle siano poste in botti aperte nei negozi non costituisce un problema?", 
            content: "Anche se i pirati sono spesso dei fuorilegge, in questo caso si tratta di un metodo legale. Si chiama 'vendita sfusa' e non è in contrasto con le norme sull'igiene. È come quando nei supermercati portuali mettiamo il pane nei sacchi…"
        },
    	{
            id: "5",
            title: "Da dove proviene Captain Candy?", 
            content: "Il primo negozio Captain Candy è stato aperto a Praga e, in seguito al successo ricevuto, abbiamo deciso di espanderci. Attualmente ci puoi trovare in diverse nazioni e stiamo lavorando per allargarci ancora di più."
        },
    	{
            id: "6",
            title: "Perché non si possono assaggiare le caramelle nelle botti?", 
            content: "Non è per motivi di igiene. Puoi comunque chiedere al nostro equipaggio direttamente nei negozi, saranno lieti di aiutarti a trovare esattamente il sapore che cerchi."
        },
    	{
            id: "7",
            title: "È igienico appoggiare le pinze sulle botti?", 
            content: "Una vecchia regola dei pirati dice che 'le pinzette e le botti si appartengono'. Inoltre, desideriamo rispettare la legge secondo cui ciò non rappresenta un problema."
        },
    	// {
        //     id: "8",
        //     title: "Quanti negozi ha Captain Candy?", 
        //     content: "Fino ad oggi abbiamo ormeggiato in quattro luoghi di Praga e in due porti: quello croato di Ragusa (Dubrovnik) e quello di Venezia. E stiamo preparando altre spedizioni!"
        // },
    	{
            id: "9",
            title: "Il concept di Captain Candy funziona anche come franchise?", 
            content: <Fragment>Certo, il concept di Captain Candy gestisce diversi negozi in franchise. Se sei interessato a collaboarare con noi, gentilmente contattaci tramite il form nella sezione  <Link to="/franchising">“Franchising“</Link>.</Fragment>
        },
    	{
            id: "10",
            title: "Le botti sono davvero completamente piene?", 
            content: "I pirati devono avere dei segreti. E quindi ti lasciamo indovinare ;)"
        },
    	{
            id: "11",
            title: "Quale caramella è la più popolare?", 
            content: "A questa domanda non riuscirebbe a rispondere neanche la più nota chiaroveggente dell'Isola delle sfere di cristallo. Ognuno sceglie secondo il proprio gusto e le caramelle più vendute cambiano spesso. Se dai un'occhiata alle botti dei negozi, puoi saperne di più."
        },
    	{
            id: "12",
            title: "Quali sono gli orari di apertura dei negozi?", 
            content: <Fragment>Dal lunedì al venerdì accogliamo qualsiasi pellegrino dalle 10 alle 23. Durante i fine settimana, invece, dalle 10 a mezzanotte. I dettagli sono disponibili <Link to="/stores">qui</Link>.</Fragment>
        },
    	{
            id: "13",
            title: "Perché il prezzo delle caramelle non è scritto su ogni botte?", 
            content: "Perché non è necessario ;) Il prezzo per 100 g è unico per tutto l'assortimento e lo trovi sempre scritto nel negozio."
        },
    ],
};

const dataProcessing = {
    en: {
        title: "Personal data processing",
        parag: [
            'Al fine di proteggere i dati personali, acconsento, in qualità di parte interessata dei dati personali, alla raccolta, conservazione e trattamento dei seguenti dati personali: nome, cognome, indirizzo di posta elettronica, indirizzo di residenza, data di nascita, numero del telefono cellulare, informazioni sul titolo di studio e altre informazioni volontariamente fornite nel questionario inviato, da parte del titolare dei dati personali, ossia: Tresher s.r.o., sede legale: Praga - Na Maninách 1040/14, Holešovice (Prague 7), 170 00 Prague, codice fiscale 24217280, partita IVA CZ24217280, registrata presso il Tribunale di Praga, atti n. 189598 C ("titolare").',
            "This consent is granted for an indefinite period until the subject withdraws his/her consent.",
            "Lo scopo del trattamento dei dati personali dell'interessato stabilito dal titolare è quello di utilizzare i dati personali solo per l'eventuale instaurazione di una cooperazione commerciale con l'interessato.",
            "Il titolare dichiara che raccoglierà i dati personali nella misura necessaria per l'adempimento delle finalità sopra indicate e che li tratterà solo in conformità con lo scopo per cui sono stati raccolti. Il titolare dichiara che i dati personali saranno trattati nel modo seguente: a) meccanicamente (automaticamente), via computer e software nell'ambito del sistema informatico; b) manualmente.",
            "Inoltre, dichiaro di essere stato informato e di conoscere l'informativa sulla privacy del titolare.",
        ]
    },
    it: {
        title: "Personal data processing",
        parag: [
            'Al fine di proteggere i dati personali, acconsento, in qualità di parte interessata dei dati personali, alla raccolta, conservazione e trattamento dei seguenti dati personali: nome, cognome, indirizzo di posta elettronica, indirizzo di residenza, data di nascita, numero del telefono cellulare, informazioni sul titolo di studio e altre informazioni volontariamente fornite nel questionario inviato, da parte del titolare dei dati personali, ossia: Tresher s.r.o., sede legale: Praga - Na Maninách 1040/14, Holešovice (Praga 7), 170 00 Praga, codice fiscale 24217280, partita IVA CZ24217280, registrata presso il Tribunale di Praga, atti n. 189598 C ("titolare").',
            "This consent is granted for an indefinite period until the subject withdraws his/her consent.",
            "Lo scopo del trattamento dei dati personali dell'interessato stabilito dal titolare è quello di utilizzare i dati personali solo per l'eventuale instaurazione di una cooperazione commerciale con l'interessato.",            
            "Il titolare dichiara che raccoglierà i dati personali nella misura necessaria per l'adempimento delle finalità sopra indicate e che li tratterà solo in conformità con lo scopo per cui sono stati raccolti. Il titolare dichiara che i dati personali saranno trattati nel modo seguente: a) meccanicamente (automaticamente), via computer e software nell'ambito del sistema informatico; b) manualmente.",
            "Il presente consenso rappresenta la manifestazione libera e informata della volontà della persona interessata dei dati personali, il cui contenuto è scelto dall'interessato stesso. Sono consapevole di poter revocare il mio consenso in qualsiasi momento. Ciò non pregiudica il diritto del titolare (o del responsabile) di trattare i dati personali se il loro utilizzo è necessario per la fornitura di un servizio o per l'adempimento di un contratto e se consentito dalla legge.",
            `Inoltre, dichiaro di essere stato informato e di conoscere l'informativa sulla privacy del titolare.`,
        ]
    },
}

export {
    faqData,
    dataProcessing,
};