import React, { useContext } from 'react';
import { Context } from '../context/Products';
import Contact from '../components/Contact/Contact';
import { contactData } from '../assets/dummyData/contactData';

const ContactPage = () => {
  const [{ lang }] = useContext(Context);
  return (
    <Contact data={contactData[lang]}/>
  )
}

export default ContactPage;