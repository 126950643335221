const sendMailData = {
    en: {
        title: "Successfully sent!",
        subTitle: "A bottle with your message in it is already on its way to us.",
        desc: "Fear not, no torture awaits, but unless you confirm your registration in the e-mail we just pinged your way, we’re under strict orders from the royal fleet to steer clear…",
        button: "Send another",
    },
    it: {
        title: "Inviato con successo!",
        subTitle: "Una bottiglia con il tuo messaggio è già in viaggio verso di noi.",
        desc: "Non temere, non ti aspetta alcuna tortura, ma a meno che tu non confermi la tua registrazione nell'e-mail che ti abbiamo appena inviato, abbiamo l'ordine severo della flotta reale di stare alla larga...",
        button: "Mandane un altro",
    },
};

const frabchisingSentData = {
    en: {
        title: "Successfully sent!",
        subTitle: "A bottle with your message in it is already on its way to us. As soon as we get your parchment, we will hurry to answer.",
        button: "Send another",
    },
    it: {
        title: "Inviato con successo!",
        subTitle: "Una bottiglia con il tuo messaggio è già in viaggio verso di noi. Non appena avremo la tua pergamena, ci affretteremo a rispondere.",
        button: "Mandane un altro",
    },
};

export {
    sendMailData,
    frabchisingSentData
};