import novinkyNaEmailEn from "../img/novinky-na-email_en.svg";
import novinkyNaEmailIt from "../img/novinky-na-email.svg";
import { Fragment } from "react";

const NewsLetterContent = {
    en: {
        img: novinkyNaEmailEn,
        title: <Fragment>Hopefully you don't see this as a threat, but if you don't confirm your registration, we'll be allowed to
        Royal Fleet not befriend you...<br />
        It's just one step – check your email box and confirm the message.<br />
        Thank you. We wish you lots of wind in the right direction.</Fragment>,
        placeholder: "Enter your e-mail address",
        buttonName: "Sing Up",
        emailExist: <Fragment>Dust and bullets, someone has already entered this email before you! <br />Enter another email, please.</Fragment>,
        error: <Fragment>Dust and bullets, someone has already entered this email before you! <br />Enter another email, please.</Fragment>,
    },
    it: {
        img: novinkyNaEmailIt,
        title: "Fino a pochi anni fa avremmo inviato le notizie su carta pergamena in una bottiglia. Fortunatamente oggi abbiamo le e-mail. Scrivi il tuo indirizzo e sarai il primo/la prima a sapere tutto delle spedizioni e dei tesori del capitano.",
        placeholder: "Inserisci l'indirizzo e-mail",
        buttonName: "Accedi",
        emailExist: <Fragment>Corpo di mille balene! Questo indirizzo e-mail è già stato immesso da qualcun altro! Immetti un altro indirizzo e-mail.</Fragment>,
        error: <Fragment>Corpo di mille balene! Questo indirizzo e-mail è già stato immesso da qualcun altro! Immetti un altro indirizzo e-mail.</Fragment>,
    },
};
const NewsLetterContentFaq = {
    en: {
        img: novinkyNaEmailEn,
        title: "Up until a few years back, our messages would have been etched on parchment and sent out in bottles. Fortunately we have e-mail these days. Write your address on the sheet to be the first to find out about all the captain’s expeditions and treasures.",
        placeholder: "Enter your e-mail address",
        buttonName: "Sing Up",
        emailExist: <Fragment>Dust and bullets, someone has already entered this email before you! <br />Enter another email, please.</Fragment>,
        error: <Fragment>Dust and bullets, someone has already entered this email before you! <br />Enter another email, please.</Fragment>,
    },
    it: {
        img: novinkyNaEmailIt,
        title: <Fragment>
                Fino a pochi anni fa avremmo inviato le notizie su carta pergamena in una bottiglia. Fortunatamente oggi abbiamo le e-mail. Scrivi il tuo indirizzo e sarai il primo/la prima a sapere tutto delle spedizioni e dei tesori del capitano.
            </Fragment>,
        placeholder: "Inserisci l'indirizzo e-mail",
        buttonName: "Accedi",
        emailExist: <Fragment>Corpo di mille balene! Questo indirizzo e-mail è già stato immesso da qualcun altro! Immetti un altro indirizzo e-mail.</Fragment>,
        error: <Fragment>Corpo di mille balene! Questo indirizzo e-mail è già stato immesso da qualcun altro! Immetti un altro indirizzo e-mail.</Fragment>,
    },
};

export {
    NewsLetterContent,
    NewsLetterContentFaq,
};