import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import prodejnyMapBg from "../../assets/img/stores/prodejny_map_bg-min.jpg";

import AnimationEffects from '../../assets/Animations/hooks/AnimationEffects';

const Stores = ({ data, cities }) => {
    console.log("cities", cities);

    const scroll = (id) => {
        const section = document.getElementById(id);
        section.scrollIntoView( { behavior: 'smooth'} );
    };
    const elementsAnimate = []
    data?.forEach((el) => {
        el.places.forEach((e) => {
            elementsAnimate.push(e.img);
        })
    });
    const { singleRefs } = AnimationEffects(elementsAnimate, data, 'fadeInY', 0.08 , 0.12);

    const { hash } = useLocation();

    useEffect(()=>{
        if (hash) {
            const section = document.querySelector(hash);
            section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
        }
     }, [hash])
      
    return (
        <div id="page-prodejny" className="page">
            {/* </div><!-- className="fade-onload" --> */}
            <header>
                <img src={prodejnyMapBg} alt="Stores map" />
                <div className="map-wrap">
                    <div id="img-map">
                        <div className="text-center">
                         
                        </div>
                    </div>
                    {cities?.map((city) => (
                        <div className="path" id={city.id} key={city.scrollId}>
                            <img src={city.path} alt={city.name} className="prodejny-map-path" />
                            <button className="map-button" onClick={() => scroll(city.scrollId)}>
                                <img src={city.img} alt={city.name} className="prodejny-map-path-city hidden-xs" />
                                <img src={city.imgXs} alt={city.name} className="prodejny-map-path-city visible-xs" />
                            </button>
                        </div>
                    ))}
                </div>
            </header>
            <section className="boxes" data-js="scroll_fullpage" data-scroll-js="normal_scroll_content_start" id="our-stores">
                <div className="container-custom" id="benatky">
                    {data?.map((store) => (
                        <div className="row-custom" id={store.id} key={store.id}>
                            <h1 className="heading-big aos-init aos-animate" data-aos="fade-up">
                                {store?.title}
                            </h1>
                            <div className="boxes-wrap">
                                {store?.places?.map((place, i) => (
                                    <div key={place?.img} className="box aos-init aos-animate opacity-0" data-aos="fade-up" data-aos-delay="200" ref={singleRefs.elements[place.img].ref}>
                                        <div className="inner">
                                            <div className="img-wrap">
                                                <img src={place?.img} alt= {place?.title} />
                                            </div>
                                            <div className="content">
                                                <h3 className="ttl">
                                                    {place.title}
                                                    {/* <!--<small>30 121 Benátky, Itálie</small>--> */}
                                                </h3>
                                                <table width="100%">
                                                    <tbody>
                                                        {!!place?.closet && (
                                                            <tr>
                                                                <td style={{color: "red"}}>{place?.closet}</td>
                                                            </tr>
                                                        )}
                                                        {place?.table?.map((e, i) => (
                                                            <tr key={`table${i}`}>
                                                                <td>{e?.open}</td>
                                                                <td>{e?.time}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <a target="_blank"  rel="noreferrer" href={place.link}>{place?.linkName}</a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    )
}

export default Stores;