import React, { useContext } from 'react';
import Stores from '../components/Stores/Stores';
import { Context } from '../context/Products';
import { StorsData, cities } from "../assets/dummyData/StorsData";
import Helmet from '../components/Helmet';
// import logo from "../assets/img/prodejny_map.png"

const StoresPage = () => {
  const [{ lang }] = useContext(Context);
  return (
    <>
      <Helmet
        title="Stors | Captain Candy"
        metaDesc="You can find our stores in various cities in Europe. But watch out! Not every wood interior store is a guarantee of Captain Candy quality. Always look for only the original Captain Candy stores, which are a safe bet. You can find a list of stores on our website."
      />
      <Stores data={StorsData[lang]} cities={cities[lang]} />
    </>
  )
}

export default StoresPage;