import logoEn from "../img/icons/en.jpg";
// import logoNl from "../imgnl/nl.png";
import logoIt from "../imgit/it.jpg";

const firstMenu = {
    en: [
        {
            id: "products",
            path: "/products",
            name: "Products",
        },
        {
            id: "captain",
            path: "/captain-story",
            name: "The Captain’s story",
        },
    ],
    it: [
        {
            id: "products",
            path: "/products",
            name: "Prodotti",
        },
        {
            id: "captain",
            path: "/captain-story",
            name: "La storia del capitano",
        },
    ],
};

const secondMenu = {
    en: [
        {
            id: "about",
            path: "/about",
            name: "About us",
        },
        {
            id: "stores",
            path: "/stores",
            name: "Stores",
        },
    ],
    it: [
        {
            id: "about",
            path: "/about",
            name: "Chi siamo",
        },
        {
            id: "stores",
            path: "/stores",
            name: "Negozi",
        },
    ],
};

const footerMenu = {
    en: [
        {
            id: "career",
            path: "/career",
            name: "Career",
        },
        {
            id: "faq",
            path: "/faq",
            name: "FAQ",
        },
        {
            id: "franchising",
            path: "/franchising",
            name: "Franchising",
        },
        {
            id: "contact",
            path: "/contact",
            name: "Contact",
        },
    ],
    it: [
        {
            id: "career",
            path: "/career",
            name: "Carriera",
        },
        {
            id: "faq",
            path: "/faq",
            name: "FAQ",
        },
        {
            id: "franchising",
            path: "/franchising",
            name: "Franchising",
        },
        {
            id: "contact",
            path: "/contact",
            name: "Contatti",
        },
    ],
};

const language = {
    en: {
        code: "it",
        name: "Italiano",
        img: logoIt,
    },
    it: {
        code: "nl",
        name: "English",
        img: logoEn,
    },
};

const footerMenuSocial = [
    {
        path: "https://www.facebook.com/captaincandy.it",
        name: "/captaincandy",
        icon: "fb",
        iconName: "facebook"
    },
    {
        path: "https://www.instagram.com/captaincandy.it",
        name: "/captaincandy",
        icon: "ig",
        iconName: "instagram"
    },
    {
        path : "https://www.tiktok.com/@captaincandyitaly",
        name: "@captaincandyitaly",
        icon: "tik",
        iconName: "tiktok"
    },
    {
        path : "https://www.linkedin.com/company/captain-candy-candy-shop",
        name: "/linkedin",
        icon: "in",
        iconName: "linkedin"
    }
];


export {
    firstMenu,
    secondMenu,
    language,
    footerMenu,
    footerMenuSocial,
};