import { Fragment } from "react";
import { Link } from "react-router-dom";
const toTop = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
};

const career = {
    en: {
        title: "Jobs currently available with us",
        accordionTitle: "Sales assistant",
        accordionSubTitle: "Set off on a pirate’s adventure with help and us other people find their personal treasure trove! The world can never have enough reliable sailors on our ship and we’re prepared to pay them their weight in gold!",
        accordion: [
            {
                id: "1",
                title: "Sales assistant",
                content:  "Set off on a pirate’s adventure with help and us other people find their personal treasure trove! The world can never have enough reliable sailors on our ship and we’re prepared to pay them their weight in gold!",
                item: [
                    {
                        title: "Why sail with us?",
                        list: [
                            "Our boat is anchored right in the heart of Venice, Florence and Verona",
                            "No matter how big the wave, we can’t be overturned, we’re a stable Czech company",
                            "Our sailors get to work flexible shifts",
                            "There’s a friendly atmosphere among members of the crew ",
                            "We offer fair and motivating pay",
                            "Opportunity to earn extra pay as part of our bonus programme",
                            "Opportunities to climb the career ladder with us – from sailor all the way to admiral",
                            "Get to choose the length of your expedition  - full or part-time hours",
                            "We’ll train you up on everything you need to know to become a fully-fledged crew member",
                        ]
                    },
                    {
                        title: "What are our expectations once you’re on board?",
                        list: [
                            "Friendly and positive behaviour when communicating with the natives as well as foreigners",
                            "Willingness to learn new skills",
                            "Flexibility to weather all storms",
                            "To work with zeal and have a healthy work ethic.",
                        ]
                    },
                    {
                        title: "What kind of work awaits our eager new recruits?",
                        list: [
                            "To help and serve everyone the tide brings in",
                            "To keep the trunks and barrels fully stocked with sweet treats",
                            "To pop down to the lower decks to pick up supplies",
                            "To scrub the decks and captain’s bridge every now and then after a hard day’s work",
                        ],
                    },
                ],
                itemFooter: "If you’re interested and keen to get on board with us, be ready to set sail right away!",
                table: <Fragment>
                        <table>
                            <thead><tr><td></td><td></td></tr></thead>
                            <tbody>
                                <tr>
                                    <td>E-mail:</td>
                                    <td>
                                        <a href="mailto:info.it@captaincandy.it"><b>info.it@captaincandy.it</b></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Mobile:</td>
                                    <td><a href="tel:+390415201519"><b> +390415201519</b></a></td>
                                </tr>
                            </tbody>
                        </table>
                    </Fragment>
            },
        ],
        itemFooter: "If you’re interested and keen to get on board with us, be ready to set sail right away!",
        inputs: [
            {
                id: "contactform_fullName",
                name: "contactform_fullName",
                label: "Name and surname",
            },
            {
                id: "contactform_email",
                name: "contactform_email",
                label: "E-mail",
            },
            {
                id: "contactform_phone",
                name: "contactform_phone",
                label: "Telephone number",
                class: "inputphone",
                style: { display: "inline-block" },
            },
            {
                id: "contactform_text",
                name: "contactform_text",
                label: "Covering letter",
                textarea: true,
            },
            {
                id: "cv",
                name: "cv",
                label: "CV",
                placeholder: "Browser",
                file: true,
            },
        ],
        link: <Fragment>“I consent to the <Link onClick={() => toTop()} to="/personal-data-processing" className="link">processing of my personal data</Link> and the responses given in the questionnaire.</Fragment>,
        button: "Send message"
    },
    it: {
        title: "Posizioni vacanti attuali",
        accordionTitle: "Assistente alla vendita",
        accordionSubTitle: "Set off on a pirate’s adventure with help and us other people find their personal treasure trove! The world can never have enough reliable sailors on our ship and we’re prepared to pay them their weight in gold!",
        accordion: [
            {
                id: "1",
                title: "Assistente alla vendita",
                content:  "Salpa con noi per vivere un'avventura da pirata e aiutare gli altri a trovare la strada verso il proprio tesoro! I marinai affidabili non sono mai troppi e compensiamo con l'oro il duro lavoro durante la navigazione!",
                item: [
                    {
                        title: "Perché salpare con noi?",
                        list: [
                            "La nostra nave è ormeggiata nel cuore di Venezia, Firenze e Verona",
                            "Nessun'onda può rovesciarci, siamo una società ceca stabile",
                            "Da noi, l'orario di lavoro dei marinai è flessibile",
                            "Nell'equipaggio vige un'atmosfera amichevole",
                            "Garantiamo una retribuzione motivante ed equa",
                            "Garantiamo inoltre compensi extra nell'ambito del programma di bonus",
                            "Possibilità di fare carriera: da marinaio ad ammiraglio",
                            "Scelta della durata della navigazione: a tempo pieno o parziale",
                            "Forniamo un corso di formazione su tutto ciò che è necessario per l'equipaggio della nave",
                        ]
                    },
                    {
                        title: "Che cosa chiediamo ai nuovi marinai?",
                        list: [
                            "Approccio gradevole e positivo nei confronti degli indigeni e dei forestieri",
                            "Disponibilità ad imparare cose nuove",
                            "Flessibilità temporale per affrontare tutte le insidie della navigazione",
                            "Lavoro con passione ed impegno sano",
                        ]
                    },
                    {
                        title: "Quali sono le mansioni dei lupi di mare?",
                        list: [
                            "Assistere e servire tutti quelli che le onde trasportano sul nostro ponte",
                            "Tenere le casse e le botti piene di tesori dolci",
                            "Saltare a prendere le razioni nel magazzino sotto coperta",
                            "Ogni tanto, dopo una giornata impegnativa, pulire la coperta della nave e il ponte del capitano",
                        ],
                    },
                ],
                itemFooter: "Se sei interessato/a ad unirti a noi, possiamo salpare subito!",
                table: <Fragment>
                        <table>
                            <thead><tr><td></td><td></td></tr></thead>
                            <tbody>
                                <tr>
                                    <td>E-mail:</td>
                                    <td>
                                        <a href="mailto:info.it@captaincandy.it"><b>info.it@captaincandy.it</b></a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Telefon:</td>
                                    <td><a href="tel:+390415201519"><b> +39 041 52 01 519</b></a></td>
                                </tr>
                            </tbody>
                        </table>
                    </Fragment>
            },
        ],
        itemFooter: "Se sei interessato/a ad unirti a noi, sappi che noi salpiamo subito!",
        inputs: [
            {
                id: "contactform_fullName",
                name: "contactform_fullName",
                label: "Nome e cognome",
            },
            {
                id: "contactform_email",
                name: "contactform_email",
                label: "E-mail",
            },
            {
                id: "contactform_phone",
                name: "contactform_phone",
                label: "Telefono",
                class: "inputphone",
                style: { display: "inline-block" },
            },
            {
                id: "contactform_text",
                name: "contactform_text",
                label: "Lettera di presentazione",
                textarea: true,
            },
            {
                id: "cv",
                name: "cv",
                label: "Curriculum vitae",
                placeholder: "Sfoglia",
                file: true,
            },
        ],
        link: <Fragment>“Acconsento al <Link onClick={() => toTop()} to="/personal-data-processing" className="link">trattamento dei dati personali</Link> e delle risposte fornite nel questionario</Fragment>,
        button: "Invia il messaggio"
    },
};

export {
    career,
};