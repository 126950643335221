import React, { useState } from 'react';
import axios from 'axios';
import { validateEmail, validatePhone, validFields } from '../../assets/function';
import Accordion from '../Accordion/Accordion';
import Helmet from '../Helmet';
import './style.css';
import { axiosLink } from '../../axiosLink';
import { SentEmail } from '../Contact/SentEmail';
import LoaderMail from '../LoaderMail/LoaderMail';

const Career = ({ data }) => {
    const [agreement, setAgreement] = useState(false);
    const [disabledSend, setDisabledSend] = useState(true);
    const [anotherEmail, setAnotherEmail] = useState(true);
    const [file, setFile] = useState();
    const [required, setRequired] = useState(false);
    const [formData, setFormData] = useState({
        contactform_fullName: "",
        contactform_email: "",
        contactform_phone: "",
        contactform_text: "",
        file: "",
    })

    const onchange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleScroll = (to) => {
        console.log("TO", to);
        const section = document.getElementById( to );
        section.scrollIntoView( { behavior: 'smooth'} );
    };

    const validInputs = (e) => {
        if (e === "contactform_email") {
            const valid = validateEmail(formData.contactform_email);
            if (valid) {
                return false;
            } else {
                return true;
            }
        } else if (e === "contactform_phone") {
            const valid = validatePhone(formData.contactform_phone);
            if (valid) {
                return false;
            } else {
                return true;
            }
        } else {
            if (formData[e]?.length > 2) {
                return false;
            } else {
                return true;
            }
        }
    }
    const handleImageChange = (e) => {
        const files = e.target.files;
        if (files?.length) {
            const file = files[0];
            const ext = file.name.split('.').pop();
            if(ext === "pdf" || ext === "docx" || ext === "doc" || ext === "jpg" || ext === "jpeg"|| ext ===  "png"|| ext ===  "xml"){
                setFile(file);
                setFormData({ ...formData, file: "Done" });
            }
            else{
                alert("Not accepted format!");
            }
        }
    };
    const sendEmail = (e) => {
        e.preventDefault();
        const fillAllFields = validFields(formData);
        if (fillAllFields) {
            setRequired(true);
            handleScroll(fillAllFields);
            return;
        }
        const valid = validateEmail(formData.contactform_email);
        if (!valid) {
            setRequired(true);
            handleScroll("contactform_email");
            return;
        }
        const validPhone = validatePhone(formData.contactform_phone);
        if (!validPhone && formData.contactform_phone) {
            setRequired(true);
            handleScroll("contactform_phone");
            return;
        }
        if (agreement) {
            setRequired(false);
            setDisabledSend(false);
            const data = new FormData();
            data.append("image", file);

            axios.post(
                `${axiosLink}/career/create`,
                data,
                {
                    params: {
                        state: "it",
                        contactform_email: formData.contactform_email,
                        contactform_fullName: formData.contactform_fullName,
                        contactform_phone: formData.contactform_phone,
                        contactform_text: formData.contactform_text,
                    }
                },
            )
            .then(res => {
                if (res) {
                    setDisabledSend(true);
                    setFile(null);
                    setAnotherEmail(false);
                    setAgreement(false);
                    setFormData({
                        contactform_fullName: "",
                        contactform_email: "",
                        contactform_phone: "",
                        contactform_text: "",
                        file: "",
                    });
                }
            }).catch((er) => {
                if (er) {
                    setDisabledSend(true);
                }
            })
        } else {
            setRequired(true);
        }
    }

  return (
    <div id="page-kariera" className="page">
        <Helmet
            title="Carrière | Captain Candy"
            metaDesc="Bij Captain Candy bieden we zoetwaren van de beste kwaliteit. U vindt er heerlijke geleien, diverse chocolaatjes, heerlijke dropsnoepjes, marshmallows, maar ook kauwgom en lolly's. Er is iets voor iedereen."
        />
        <header id="main"></header>
        <div className="container-custom" id="page-faq">
            <h1 className="page-heading fade-onload">
                {data?.title}
            </h1>
            <Accordion data={data?.accordion} />
            <div className="content-1200">
                <h2 className="heading aos-init aos-animate" data-aos="fade-up">
                    {data?.itemFooter}
                </h2>
            </div>
            {anotherEmail ? (
                <form id="contact_form" onSubmit={sendEmail} className="validate aos-init aos-animate">
                    {data?.inputs?.map((element) => (
                        <div key={element.id} id={element.id} className={`form-group ${(required && (validInputs(element.name))) && "has-error has-danger"}`}>
                            {!element?.file && <label  className="textarea" htmlFor={element.id}>{element.label}</label>}
                            {element?.file ? (
                                <div className={`form-group relative upload-wrap ${(!file && required) && "has-error has-danger"}`}>
                                    <label>{element.label}</label>
                                    <button className="btn">
                                        {element.placeholder}...
                                        <input id="file_upload" type="file" name="file_1" size="40" onChange={(e) => handleImageChange(e)} />
                                        {!file && <span className="valid-icon" style={{ top: "7px", right: "8px" }}></span>}
                                    </button>
                                    {file && <span className="upload-file-info selected">{file.name}</span>}
                                </div>
                            ) : (
                                <div className="berlicka">
                                    {element?.textarea ? (
                                        <textarea name={element.name} id={element.id} onChange={onchange}  className="form-control" rows="4"></textarea>
                                    )
                                    : (
                                        <input name={element.name} type="text"  onChange={onchange}  className={`${element?.class} form-control`} id={element.id} placeholder="" required="" />
                                    )}
                                    <span className="valid-icon"></span>
                                </div>
                            )}
                            
                        </div>
                    ))}
                    {/* <div className="form-group relative upload-wrap">
                        <label>CV</label>
                        <a className="btn" href="javascript:;">
                            Browse…
                            <input id="file_upload" type="file" name="file_1" size="40" accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={() => {}} />
                        </a>
                        <span className="upload-file-info"></span>
                    </div> */}

                    <div className="clearfix"></div>
                    <div className={`form-group agree ${(required && !agreement) && "has-error has-danger"}`}>
                        <label></label>
                        <div className="checkbox-wrap">
                            <input type="checkbox" id="checkbox"  checked={agreement} name="agreement"  onChange={() => setAgreement(!agreement)} />
                            <label htmlFor="checkbox" className="circle"></label>
                            <label htmlFor="checkbox" className="label">{data?.link}</label>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                    <button type="submit" className={`btn btn-primary ${(!agreement || !disabledSend) && "disabled"}`} disabled={!agreement}>{disabledSend ? data?.button : <LoaderMail />}</button>
                </form>
            ) : (
                <SentEmail sendAnotherEmail={(e) => setAnotherEmail(e)} />
            )}

            <div className="msg-send not-send hidden" id="contact_form_send_error">
                <span className="ttl">
                    E-mail was not sent
                </span>
                <p>
                    Something went wrong, please contact us in another way
                </p>
                <button type="submit" className="btn btn-primary" data-contactform-resend="1">Try again</button>
            </div>
        </div>
    </div>
  )
}

export default Career;