const newsletter = {
    en: {
        title: "WANT TO BE THE FIRST?",
        subttl: "Back in the olden days, you couldn’t get hold of exclusive information without having to undergo torture, walk the plank or be subjected to any keelhauling. These days you just need to send us an e-mail and hey presto!",
        placeholder: "Enter your e-mail address",
        button: "Sing up",
        error: "Body of a thousand whales! This email address has already been entered by someone else! Enter another email address."
    },
    it: {
        title: "Vuoi essere il primo/la prima?",
        subttl: "In passato, l'acquisizione di informazioni esclusive prevedeva torture, camminate sulla passerella fuori bordo e giri di chiglia. Oggi basta scrivere un'e-mail e puoi ricevere tutto ciò che desideri fino a casa!",
        placeholder: "Inserisci l'indirizzo e-mail",
        button: "Accedi",
        error: "Corpo di mille balene! Questo indirizzo e-mail è già stato immesso da qualcun altro! Immetti un altro indirizzo e-mail.",
    },
};

export {
    newsletter
};