import React, { useState, useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { firstMenu, language, secondMenu } from '../../assets/dummyData/Menu';
import { Context } from '../../context/Products';
// import logoNl from "../../assets/imgnl/image001.jpg";

const NavBar = ({ activeClass }) => {
    const [state, dispatch] = useContext(Context);
    const { lang } = state;
    // const { pathname } = useLocation();
    // const [langCode, setLangCode] = useState("nl");
    const [openMenu, setOpenMenu] = useState(false);
    const toggleMenu = () => {
        setOpenMenu(!openMenu);
        if (window.innerWidth < 768) {
            document.body.classList.toggle('overflow-h');
        }
    }
    const changeLanguage = () => {
        // const languageSwitch = (lang === "en") ? "de" : "en";
        const languageSwitch = (lang === "en") ? "it" : "en";
        localStorage.setItem("lang", languageSwitch);
        dispatch({
            type: "LANG",
            payland: {
                lang: languageSwitch,
            }
        });
    }
  return (
    <nav className={`navbar navbar-default ${activeClass === "/" && "navbar-hp"}`} role="navigation" id="top">
        <div className="container-custom">
            <div className="navbar-header">
                <div className="brand-logo-wrap"><Link className="brand-logo" to="/"></Link></div>
                <button type="button" onClick={() => toggleMenu()} className={`${openMenu && "in"} ${lang} navbar-toggle`}>
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                </button>
            </div>
            <div className={`${openMenu && "in"} collapse navbar-collapse navbar-ex1-collapse`}>
                <div id="top-bar">
                    <div className="container-custom">
                        <div id="lang-switch">
                            <button className="btn" type="button" onClick={() =>changeLanguage()}>
                                <img src={language[lang]?.img} alt="Logo" /><span>{language[lang].name}</span>
                            </button>
                        </div>
                        {/* <!--
                        <div id="support-service">
                        <span className="icon"></span>
                            <span className="ttl">Customer happiness manager: </span><br>   
                            <a href="tel:+420 739 287 144">+420 739 287 144</a>,
                            <small>Mon — Fri, 9—17 h</small>
                        </div>
                        --> */}
                        {/* <!--
                        <div id="user-entry">
                            <span>Přihlášení / Registrace</span>
                        </div>
                        --> */}
                    </div>
                </div>

                <ul className="nav navbar-nav navbar-left">
                    {firstMenu[lang].map((el) => (
                        <li key={el.id} className={activeClass === el.path ? "active" : ""}>
                            <NavLink to={el.path} onClick={() => toggleMenu()}>{el.name}</NavLink>
                        </li>
                    ))}
                    <div className="brand-logo-wrap">
                        <NavLink
                            className="brand-logo"
                            onClick={() => toggleMenu()}
                            to="/"
                        />
                    </div>
                </ul>
                <div className="brand-logo-wrap"><NavLink className="brand-logo" to="/"></NavLink></div>
                <ul className="nav navbar-nav navbar-right">
                    {secondMenu[lang].map((el, i) => (
                        <li key={el.id} className={`${(activeClass === el.path) ? "active" : ""} ${i > 0 && "last"}`}>
                            <NavLink to={el.path} onClick={() => toggleMenu()}>{el.name}</NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    </nav>
  )
}

export default NavBar;
