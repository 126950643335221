import axios from 'axios';
import React, { useState } from 'react';
import { validateEmail, validatePhone, validFields } from '../../assets/function';
import { axiosLink } from '../../axiosLink';
import Helmet from '../Helmet';
import { FrabchisingSent } from './FrabchisingSent';
import LoaderMail from '../LoaderMail/LoaderMail';

const Franchising = ({ data }) => {
    const [agreement, setAgreement] = useState(false);
    const [anotherEmail, setAnotherEmail] = useState(true);
    const [disabledSend, setDisabledSend] = useState(true);
    const [file, setFile] = useState();
    const [required, setRequired] = useState(false);
    const [formData, setFormData] = useState({
        contactform_fullName: "",
        contactform_email: "",
        contactform_phone: "",
        file: "",
        contactform_text_1: "",
        contactform_text_2: "",
        contactform_text_3: "",
        contactform_text_4: "",
        contactform_text_5: "",
        contactform_text_6: "",
        contactform_text_7: "",
        contactform_text_8: "",
        contactform_text_9: "",
        contactform_text_10: "",
        contactform_text_11: "",
        contactform_text_12: "",
        contactform_text_13: "",
        contactform_text_14: "",
    });

    const onchange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const validInputs = (e) => {
        if (e === "contactform_email") {
            const valid = validateEmail(formData.contactform_email);
            if (valid) {
                return false;
            } else {
                return true;
            }
        } else if (e === "contactform_phone") {
            const valid = validatePhone(formData.contactform_phone);
            if (valid) {
                return false;
            } else {
                return true;
            }
        } else {
            if (formData[e]?.length > 2) {
                return false;
            } else {
                return true;
            }
        }
    }

    const handleImageChange = (e) => {
        const files = e.target.files;
        if (files?.length) {
            const file = files[0];
            const ext = file.name.split('.').pop();
            if(ext === "pdf" || ext === "docx" || ext === "doc" || ext ===  "xml"){
                setFile(file);
                setFormData({ ...formData, file: "Done" })
            }
            else{
                setFormData({ ...formData, file: "" })
                alert("Not accepted format!");
            }
        }
    };

    const handleScroll = (to) => {
        const section = document.getElementById( to );
        section.scrollIntoView( { behavior: 'smooth'} );
    };

    const sendEmail = (e) => {
        e.preventDefault(formData);


        const valid = validateEmail(formData.contactform_email);
        if (!valid) {
            setRequired(true);
            handleScroll("contactform_email");
            return;
        }
        const validPhone = validatePhone(formData.contactform_phone);
        if (!validPhone) {
            setRequired(true);
            handleScroll("contactform_phone");
            return;
        }

        const fillAllFields = validFields(formData);
        if (fillAllFields) {
            setRequired(true);
            handleScroll(fillAllFields);
            return;
        }


        if (agreement && !fillAllFields && valid && validPhone) {
            setDisabledSend(false);
            setRequired(false);
            const data = new FormData();
            data.append("image", file);

            axios.post(
                `${axiosLink}/franchising/create`,
                data,
                {
                    params: {
                        state: "it",
                        contactform_email: formData.contactform_email,
                        contactform_fullName: formData.contactform_fullName,
                        contactform_phone: formData.contactform_phone,
                        contactform_text_1: formData.contactform_text_1,
                        contactform_text_2: formData.contactform_text_2,
                        contactform_text_3: formData.contactform_text_3,
                        contactform_text_4: formData.contactform_text_4,
                        contactform_text_5: formData.contactform_text_5,
                        contactform_text_6: formData.contactform_text_6,
                        contactform_text_7: formData.contactform_text_7,
                        contactform_text_8: formData.contactform_text_8,
                        contactform_text_9: formData.contactform_text_9,
                        contactform_text_10: formData.contactform_text_10,
                        contactform_text_11: formData.contactform_text_11,
                        contactform_text_12: formData.contactform_text_12,
                        contactform_text_13: formData.contactform_text_13,
                        contactform_text_14: formData.contactform_text_14,
                    }
                },
            )
            .then(res => {
                if (res) {
                    setFile(null);
                    setDisabledSend(true);
                    setAnotherEmail(false);
                    setFormData({
                        contactform_fullName: "",
                        contactform_email: "",
                        contactform_phone: "",
                        file: "",
                        contactform_text_1: "",
                        contactform_text_2: "",
                        contactform_text_3: "",
                        contactform_text_4: "",
                        contactform_text_5: "",
                        contactform_text_6: "",
                        contactform_text_7: "",
                        contactform_text_8: "",
                        contactform_text_9: "",
                        contactform_text_10: "",
                        contactform_text_11: "",
                        contactform_text_12: "",
                        contactform_text_13: "",
                        contactform_text_14: "",
                    });
                }
            }).catch((err) => {
                setDisabledSend(true);
            })
        } else {
            setRequired(true);
        }
    }
    return (
        <div id="page-faq" className="page page-plain fade-onload">
            <Helmet
                title="Franchising | Captain Candy"
                metaDesc="You can find our stores in various cities in Europe. But watch out! Not every wood interior store is a guarantee of Captain Candy quality. Always look for only the original Captain Candy stores, which are a safe bet. You can find a list of stores on our website."
            />
            <header id="main"></header>
            <div className="container-custom">
                <h1 className="page-heading">
                    {data?.title || "Captain Candy Franchising"}
                </h1>
                <div className="content-1400">
                    <div data-aos="fade-up">
                        {data?.history?.map((el, index) => (
                            <div key={index}>
                                <p><b>{el.question}</b></p>
                                <p><b>{el.answer}</b></p>
                            </div>
                        ))}
                    </div>
                    <div data-aos="fade-up">
                        {data?.list?.map((e, index) => (
                            <div key={`test${index}`}>
                                <h2 className="heading">
                                    {e?.headingtitle}
                                </h2>
                                <ul>
                                    {e?.headingLi?.map((el, i) => (
                                        <li key={`list-${i}`}>{el}</li>
                                    ))}
                                </ul>
                            </div>
                        ))}

                        <h2 className="heading">
                            {data?.listParagraphsTitle}
                        </h2>
                        {data?.listParagraphs?.map((element, index) => (
                            <p key={`form-${index}`}>{element}</p>
                        ))}
                    </div>
                </div>
                {/* <!-- form --> */}
                <h2 className="heading-contactform" data-aos="fade-up" id="kontakt">
                    {data?.questionTitle}
                </h2>
                {/* <!-- fransizing formular --> */}

                {anotherEmail ? (
                    <form id="contact_form" onSubmit={sendEmail} className="validate full-width fade-up">
                        {data?.inputs?.map((input) => (
                            <div key={input.id}>
                            {input.id === "file_upload" && (
                                <>
                                    <div className={`form-group relative upload-wrap ${(!file && required) && "has-error has-danger"}`} id="file">
                                        <label className="no-margin" style={{ minHeight: "45px" }}>{input?.label}</label>
                                        <div className="clearfix"></div>
                                        <div className="spacer5 visible-xs"></div>
                                        <button className='btn'>
                                            {input?.button}...
                                            <input
                                                id={input.id}
                                                type="file"
                                                name={input.id}
                                                size="40"
                                                onChange={(e) => handleImageChange(e)}
                                            />
                                            <span className="valid-icon" style={{ top: "7px", right: "8px" }}></span>
                                        </button>
                                        {file && <span className="upload-file-info selected">{file.name}</span>}
                                    </div>
                                    <div className="clearfix"></div>
                                    <div className="spacer30"></div>
                                </>
                            )}
                            {input.id !== "file_upload" && (
                                <div className={`form-group ${(required && (validInputs(input.name))) && "has-error has-danger"}`} key={input.id} id={input.name}>
                                    <label className={input?.textarea ? "textarea" : " "} htmlFor={input.id}>{input.label}</label>
                                    <div className="berlicka" style={input?.style || {} }>
                                        {input?.textarea ? (
                                            <textarea name={input.name} id={input.name} onChange={onchange} className="form-control" rows="4"></textarea>
                                        )
                                        : (
                                            <input name={input.name} type="text" onChange={onchange} className={`${input?.class} form-control`} id={input.id} placeholder="" required="" />
                                        )}
                                        <span className="valid-icon"></span>
                                    </div>
                                </div>
                            )}
                            </div>
                        ))}
                        <div className="clearfix"></div>
                        <div className={`form-group agree ${(required && !agreement) && "has-error has-danger"}`}>
                            <label></label>
                            <div className="checkbox-wrap">
                                <input type="checkbox" id="checkbox" checked={agreement} name="agreement"  onChange={() => setAgreement(!agreement)} />
                                <label htmlFor="checkbox" className="circle"></label>
                                <label htmlFor="checkbox" className="label">{data?.link}</label>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                        <button type="submit" className={`btn btn-primary ${disabledSend && "disabled"}`}>{disabledSend ? data?.button : <LoaderMail />}</button>
                    </form>
                ) : (
                    <FrabchisingSent sendAnotherEmail={(e) => setAnotherEmail(e)} />
                )}
                <div className="msg-send not-send hidden" id="contact_form_send_error">
                    <span className="ttl">
                        E-mail was not sent
                    </span>
                    <p>
                        Something went wrong, please contact us in another way
                    </p>
                    <button type="submit" className="btn btn-primary" data-contactform-resend="1">Try again</button>
                </div>
                <div className="clearfix"></div>
                <div className="spacer70"></div>
                <div className="spacer50"></div>
            </div>
        </div>
    )
};

export default Franchising;
