import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Logo from "../../assets/img/brand-logo.svg";
import AccordionMap from '../AccordionMap/AccordionMap';
import Maps from './Maps';

const HpMap = ({ data, locations }) => {
    const [ID, setID] = useState(null);
    const navigate = useNavigate();
    
    const goToURL = (e) => {
        const ridirect = `/stores#${e}`;
        navigate(ridirect);
    }

    return (
        <section id="map" className="fade-onload" data-js="scroll_fullpage">
            <div className="container-custom">
                <span className="hidden-xs hidden-sm">
                    <img src={Logo} alt="Captain Candy" id="map-logo" /> 
                </span>
                <h2 className="section-heading">
                    {data?.title}
                </h2>
                <div className="map-navigation hidden-xs hidden-sm" data-aos="fade-left">
                    {locations?.map((el) => (
                        <div
                            onMouseOut={() => setID(null)}
                            onMouseOver={() => setID(el.id)}
                            key={el.id}
                            className={`item item-map-1 cp ${el.id === ID ? "active" : ""}`}
                            onClick={() => goToURL("venecie")}
                        >
                        <h3 className="ttl">
                            {el.title}
                        </h3>
                        <div className="table-wrap">
                        <table>
                          <tbody>
                              {el?.table?.map((e, i) => (
                                  <tr key={`el${i}`}>
                                      <td>{e?.open}</td>
                                      <td>{e?.time}</td>
                                  </tr>
                              ))}
                          </tbody>
                        </table>
                            <hr />
                        </div>
                    </div>
                    ))}
                </div>

                <div id="map_div-wrap" className="hidden-xs hidden-sm">
                    <a href="/stores#venecie" className="btn btn-primary btn-arrow btn-more-info">{data?.button?.name}</a>
                    {/* <div id="map_div">
                    </div> */}
                    <Maps handechanges={(e) => setID(e)} id={ID} locations={locations} />
                </div>
            </div>
            <div className="visible-xs visible-sm">
                <AccordionMap data={locations} />
            </div>
        </section>
    )
}

export default HpMap;
