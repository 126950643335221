import { Fragment } from "react";

const pageNotFoundData = {
    en: {
        title: "Page not found",
        description: <Fragment>This chest is&nbsp;empty. <br /> The site you are looking for can not appear on&nbsp;the maps. <br /> Continue to&nbsp;homepage.</Fragment>,
        button: "Homepage"
    },
    it: {
        title: "La pagina non è stata trovata",
        description: "Questo scrigno è vuoto. Non siamo riusciti a trovare la pagina che stai cercando nelle nostre mappe, torna al porto iniziale",
        button: "Porto iniziale"
    }
};

export {
    pageNotFoundData
};
