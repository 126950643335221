import { Fragment } from "react";

const emailSuccessData = {
    en: {
        title: "Confirm your e-mail – it’s torture-free we promise.",
        subttl: "We will not torture you, but if you don't confirm your login in the e-mail we just sent to you, you are forbidden to join the royal fleet ...",
        subttl1: "You’re just one step away – so get over to that inbox and confirm the message.",
        subttl2: "Thanks and here’s to a plain sailing voyage together."
    },
    it: {
        title: "Conferma il tuo indirizzo e-mail se non vuoi essere torturato.",
        subttl: "Non ti tortureremo, ma se non confermi le tue credenziali nell'e-mail che ti abbiamo appena inviato, la flotta reale ci vieterà di essere amici... ",
        subttl1: <Fragment>Manca solo un piccolo passo - <b>vai al tuo scrigno della posta elettronica e conferma il messaggio</b>.</Fragment>,
        subttl2: "Grazie e buon vento",
    }
};

const emailConfirmData = {
    en: {
        title: "We have it!",
        subttl: "Your email has been successfully stored in our hold and is coming with us on our expeditions.",
        subttl1: "Just so you know - we've got a shipwrecked scribe on board, so he'll write you a scroll as soon as something new happens.",
        subttl2: "Look forward!",
        buttonName: "Continue",
    },
    it: {
        title: "Ce l'abbiamo!",
        subttl: "La tua e-mail è stata archiviata con successo nella nostra stiva e verrà con noi nelle nostre spedizioni",
        subttl1: "Giusto perché tu lo sappia, abbiamo a bordo uno scrivano naufrago, quindi ti scriverà una pergamena non appena accadrà qualcosa di nuovo.",
        subttl2: "Attendo con ansia!",
        buttonName: "Continua",
    },
};

export {
    emailSuccessData, emailConfirmData
};