import mapMarker from "../img/icons/map_marker.svg";

const locations = {
    en: [
            {
                id: "1",
                position: { lat: 45.4390208, lng: 12.3371627 },
                title: "Salizada San Giovanni Grisostomo 5803",
                link: "https://www.google.cz/maps/place/Salizada+S.+Giovanni+Grisostomo,+5803,+30121+Venezia+VE,+It%C3%A1lie/@45.4390141,12.3349899,17z/data=!3m1!4b1!4m5!3m4!1s0x477eb1dbee572bd1:0x87490efe04ea7997!8m2!3d45.4390141!4d12.3371786",
                icon: mapMarker,
                table: [
                    {
                        open: "Monday - Thursday",
                        time: "10:00—22:00",
                    },
                    {
                        open: "Friday - Saturday",
                        time: "10:00—23:00",
                    },
                    {
                        open: "Sunday",
                        time: "10:00—22:00",
                    },
                ]
            },
            {
                id: "2",
                position: { lat: 45.4362676, lng: 12.3356981 },
                title: "San Marco 5037",
                link: "https://www.google.cz/maps/place/S.+Marco,+4742-4743,+30100+Venezia+VE,+It%C3%A1lie/@45.4362713,12.3335094,17z/data=!3m1!4b1!4m5!3m4!1s0x477eb1da159153df:0x4a162b15fe2576a6!8m2!3d45.4362676!4d12.3356981",
                icon: mapMarker,
                table: [
                    {
                        open: "Monday - Thursday",
                        time: "10:00—22:00",
                    },
                    {
                        open: "Friday - Saturday",
                        time: "10:00—23:00",
                    },
                    {
                        open: "Sunday",
                        time: "10:00—22:00",
                    },
                ]
            }
        ],
    it:  [
        {
            id: "1",
            position: { lat: 45.4390208, lng: 12.3371627 },
            title: "Salizada San Giovanni Grisostomo 5803",
            link: "https://www.google.cz/maps/place/Salizada+S.+Giovanni+Grisostomo,+5803,+30121+Venezia+VE,+Italy/@45.4390208,12.3371627,17z/data=!3m1!4b1!4m5!3m4!1s0x477eb1dbee572bd1:0x87490efe04ea7997!8m2!3d45.4390141!4d12.3371786",
            icon: mapMarker,
            table: [
                {
                    open: "Lun—Gio",
                    time: "10:00—22:00",
                },
                {
                    open: "Ven—Sab",
                    time: "10:00—23:00",
                },
                {
                    open: "Domenica",
                    time: "10:00—22:00",
                },
            ]
        },
        {
            id: "2",
            position: { lat: 45.4362676, lng: 12.3356981 },
            title: "San Marco 5037",
            link: "https://www.google.cz/maps/place/S.+Marco,+4742,+30124+Venezia+VE,+Italy/@45.4362676,12.3356981,17z/data=!3m1!4b1!4m5!3m4!1s0x477eb1da159153df:0x4a162b15fe2576a6!8m2!3d45.4362676!4d12.3356981",
            icon: mapMarker,
            table: [
                {
                    open: "Lun—Gio",
                    time: "10:00—22:00",
                },
                {
                    open: "Ven—Sab",
                    time: "10:00—23:00",
                },
                {
                    open: "Domenica",
                    time: "10:00—22:00",
                },
            ]
        }
    ]
}

export {
    locations
};
