import React, { useContext } from 'react';
import { homePageData, promoTextHeader } from '../assets/dummyData/homePage';
import { boxes, HpMapData } from '../assets/dummyData/HpBoxesData';
import { locations } from '../assets/dummyData/map';
import Helmet from "../components/Helmet";
import baner1 from "../assets/img/kariera_header.png";

import Header from '../components/Header/Header';
import HpAbout from '../components/HpAbout/HpAbout';
import HpBoxes from '../components/HpBoxes/HpBoxes';
import HpMap from '../components/Hpmap/HpMap';
import { Context } from '../context/Products';

const HomePage = () => {
  const [{ lang }] = useContext(Context);
  return (
    <div id="page-hp" className="page">
         <Helmet
          title="Candy Store | Captain Candy"
          metaDesc="At Captain Candy we offer the best quality candies. You will find delicious jellies, selected chocolates, great licorice candies, marshmallows, but also chewing gum and lollipops. Everyone really chooses."
          opengraphImage={baner1}
        />
        <Header promoText={promoTextHeader[lang]} />
        <HpAbout
          data={homePageData[lang]}
        />
        <HpBoxes
          boxes={boxes[lang]}
          title={boxes.title[lang]}
        />
        <div className="clearfix" />
        <HpMap data={HpMapData[lang]} locations={locations[lang]} />
    </div>
  )
}

export default HomePage;
