import benatky_1 from "../img/stores/benatky_1-min.jpg";
import benatky_2 from "../img/stores/benatky_2-min.jpg";
import benatky_3 from "../img/stores/benatky_3-min.jpg";
import verona_1 from "../img/stores/verona_1-min.jpg";
import verona_2 from "../img/stores/verona_2-min.jpg";
import praha_1 from "../img/stores/praha_1-min.jpg";
import praha_2 from "../img/stores/praha_2-min.jpg";
import florencie_1 from "../img/stores/florencie_1.webp";
import amstrdam_1 from "../img/stores/amstrdam_1-min.jpg";
import amstrdam_2 from "../img/stores/amstrdam_2-min.jpg";
import kolin_nad_rynem_1 from "../img/stores/kolin_nad_rynem_1-min.jpg";
import roma from "../img/stores/roma.webp";
import Kalverstraat from "../img/stores/Kalverstraat.webp";

import mapMarker from "../img/icons/map_marker.svg";

import prodejny_map_path_praha from "../img/stores/prodejny_map_path_praha.svg";
import prodejny_map_path_city_praha_xs_it from "../img/stores/prodejny_map_path_city_praha-xs_it.svg";
import prodejny_map_path_city_praha from "../img/stores/prodejny_map_path_city_praha.svg";
import prodejnyMapPathCityPrahaXs from "../img/stores/prodejny_map_path_city_praha-xs.svg";
import prodejny_map_path_benatky from "../img/stores/prodejny_map_path_benatky.svg";
import prodejny_map_path_city_benatky from "../img/stores/prodejny_map_path_city_benatky.svg";
import prodejny_map_path_city_benatkyXs from "../img/stores/prodejny_map_path_city_benatky-xs.svg";
import prodejny_map_path_verona from "../img/stores/prodejny_map_path_verona.svg";
import prodejny_map_path_city_verona from "../img/stores/prodejny_map_path_city_verona.svg";
import prodejny_map_path_city_veronaXs from "../img/stores/prodejny_map_path_city_verona-xs.svg";
import prodejny_map_path_amsterdam from "../img/stores/prodejny_map_path_amsterdam.svg";
import prodejny_map_path_city_amsterdam from "../img/stores/prodejny_map_path_city_amsterdam.svg";
import prodejny_map_path_city_amsterdamXs from "../img/stores/prodejny_map_path_city_amsterdam-xs.svg";
import prodejny_map_path_florencie from "../img/stores/prodejny_map_path_florencie.svg";
import prodejny_map_path_city_florencie from "../img/stores/prodejny_map_path_city_florencie.svg";
import prodejny_map_path_city_florencieXs from "../img/stores/prodejny_map_path_city_florencie-xs.svg";
import prodejny_map_path_kolin from "../img/stores/prodejny_map_path_kolin.svg";
import prodejny_map_path_city_kolin from "../img/stores/prodejny_map_path_city_kolin.svg";
import prodejny_map_path_city_kolinXs from "../img/stores/prodejny_map_path_city_kolin-xs.svg";
import prodejny_map_path_city_kolin_it from "../img/stores/prodejny_map_path_city_kolin_it.svg";
import prodejny_map_path_city_kolin_xs from "../img/stores/prodejny_map_path_city_kolin-xs_it.svg";
import prodejny_map_path_city_benatky_it from "../img/stores/prodejny_map_path_city_benatky_it.svg";
import prodejny_map_path_city_florencie_it from "../img/stores/prodejny_map_path_city_florencie_it.svg";
import prodejny_map_path_city_praha_it from "../img/stores/prodejny_map_path_city_praha_it.svg";
import prodejny_map_path_city_benatky_xs_it from "../img/stores/prodejny_map_path_city_benatky-xs_it.svg";
import prodejny_map_path_city_florencie_xs_it from "../img/stores/prodejny_map_path_city_florencie-xs_it.svg";
import prodejny_map_path_city_rome from "../img/stores/prodejny_map_path_city_rome.svg";
import prodejny_map_path_city_rome_xs from "../img/stores/prodejny_map_path_city_rome-xs.svg";
import prodejny_map_path_rome from "../img/stores/prodejny_map_path_rome.svg";

const StorsData = {
    en: [
        {
            id: "venecie",
            title: "Our stores in Venice",
            places: [
                {
                    img: benatky_1,
                    title: "Salizada San Giovanni Grisostomo 5803",
                    link: "https://www.google.cz/maps/place/Salizada+S.+Giovanni+Grisostomo,+5803,+30121+Venezia+VE,+It%C3%A1lie/@45.4390141,12.3349899,17z/data=!3m1!4b1!4m5!3m4!1s0x477eb1dbee572bd1:0x87490efe04ea7997!8m2!3d45.4390141!4d12.3371786",
                    linkName: "Show on map",
                    table: [
                        {
                            open: "Monday - Thursday",
                            time: "10:00—22:00",
                        },
                        {
                            open: "Friday - Saturday",
                            time: "10:00—23:00",
                        },
                        {
                            open: "Sunday",
                            time: "10:00—22:00",
                        },
                    ]
                },
                {
                    img: benatky_2,
                    title: "San Marco 5037",
                    link: "https://www.google.cz/maps/place/S.+Marco,+4742-4743,+30100+Venezia+VE,+It%C3%A1lie/@45.4362713,12.3335094,17z/data=!3m1!4b1!4m5!3m4!1s0x477eb1da159153df:0x4a162b15fe2576a6!8m2!3d45.4362676!4d12.3356981",
                    linkName: "Show on map",
                    table: [
                        {
                            open: "Monday - Thursday",
                            time: "10:00—22:00",
                        },
                        {
                            open: "Friday - Saturday",
                            time: "10:00—23:00",
                        },
                        {
                            open: "Sunday",
                            time: "10:00—22:00",
                        },
                    ]
                },
                {
                    img: benatky_3,
                    title: "Castello 5577/5578",
                    link: "https://www.google.cz/maps/place/S.+Marco,+4742-4743,+30100+Venezia+VE,+It%C3%A1lie/@45.4362713,12.3335094,17z/data=!3m1!4b1!4m5!3m4!1s0x477eb1da159153df:0x4a162b15fe2576a6!8m2!3d45.4362676!4d12.3356981",
                    linkName: "Show on map",
                    table: [
                        {
                            open: "Monday - Thursday",
                            time: "10:00—22:00",
                        },
                        {
                            open: "Friday - Saturday",
                            time: "10:00—23:00",
                        },
                        {
                            open: "Sunday",
                            time: "10:00—22:00",
                        },
                    ]
                },
            ]
        },
        {
            id: "prague",
            title: "Our stores in Prague",
            places: [
                {
                    img: praha_1,
                    title: "Na Příkopě 10",
                    link: "https://goo.gl/maps/XFnaAuFP8e82",
                    linkName: "Show on map",
                    closet: "Temporarily closed",
                    // table: [
                    //     {
                    //         open: "Monday - Sunday",
                    //         time: "10:00—21:00",
                    //     },
                    // ]
                },
                {
                    img: praha_2,
                    title: "Karlova 7",
                    link: "https://goo.gl/maps/3c9qQmG18m82",
                    linkName: "Show on map",
                    table: [
                        {
                            open: "Monday - Sunday",
                            time: "10:00—21:00",
                        },
                    ]
                },
            ]
        },
        {
            id: "verona",
            title: "Our stores in Verona",
            places: [
                {
                    img: verona_1,
                    title: "Via Cappello 6",
                    link: "https://www.google.cz/maps/place/Via+Cappello,+6,+37121+Verona+VR,+It%C3%A1lie/@45.4420786,10.9979177,21z/data=!4m5!3m4!1s0x477f5f490033de7d:0x547252f32b8cb95d!8m2!3d45.4420523!4d10.9979918",
                    linkName: "Show on map",
                    table: [
                        {
                            open: "Monday - Thursday",
                            time: "10:00—21:00",
                        },
                        {
                            open: "Friday - Saturday",
                            time: "10:00—22:00",
                        },
                        {
                            open: "Sunday",
                            time: "10:00—21:00",
                        },
                    ]
                },
                {
                    img: verona_2,
                    title: "Via Mazzini 78",
                    link: "https://goo.gl/maps/dPopaAr6mCsU2APZ8",
                    linkName: "Show on map",
                    table: [
                        {
                            open: "Monday - Thursday",
                            time: "10:00—21:00",
                        },
                        {
                            open: "Friday - Saturday",
                            time: "10:00—22:00",
                        },
                        {
                            open: "Sunday",
                            time: "10:00—21:00",
                        },
                    ]
                },
            ]
        },
        {
            id: "rome",
            title: "Our store in Rome",
            places: [
                {
                    img: roma,
                    title: "Piazza di Trevi 98",
                    link: "https://www.google.com/maps/place/Piazza+di+Trevi,+98,+00187+Roma+RM,+Italy/@41.9007678,12.4830537,17z/data=!3m1!4b1!4m6!3m5!1s0x132f605327b22a25:0xb5ace38e52288812!8m2!3d41.9007678!4d12.4830537!16s%2Fg%2F11c5pl3jlq?entry=ttu",
                    linkName: "Show on map",
                    table: [
                        {
                            open: "Monday - Thursday",
                            time: "10:00—00:00",
                        },
                        {
                            open: "Friday - Sunday",
                            time: "10:00—01:00",
                        },
                    ]
                },
            ]
        },
        {
            id: "florence",
            title: "Our stores in Florence",
            places: [
                {
                    img: florencie_1,
                    title: "Via Por Santa Maria 38",
                    link: "https://www.google.com/maps/d/u/0/viewer?mid=16L0Iy3_BQDF53QHei8EzXaYBPHI&hl=en_US&ll=43.76910325004388%2C11.254112999999982&z=21",
                    // link: "https://www.google.com/maps/place/Via+dei+Calzaiuoli,+42,+50122+Firenze+FI,+It%C3%A1lie/@43.7707921,11.2554196,17z/data=!3m1!4b1!4m5!3m4!1s0x132a54010999f27b:0xaf2f378a984f75f2!8m2!3d43.7707921!4d11.2554196?hl=cs-CZ",
                    linkName: "Show on map",
                    table: [
                        {
                            open: "Monday - Thursday",
                            time: "10:00—21:00",
                        },
                        {
                            open: "Friday - Saturday",
                            time: "10:00—22:00",
                        },
                        {
                            open: "Sunday",
                            time: "10:00—21:00",
                        },
                    ]
                },
            ]
        },
        {
            id: "amsterdam",
            title: "Our stores in Amsterdam",
            places: [
                {
                    id: "1",
                    position: { lat:  52.37069480816792, lng: 4.891576315967946 },
                    icon: mapMarker,
                    img: amstrdam_1,
                    title: "Kalverstraat 80-82",
                    link: "https://www.google.com/maps/place/Kalverstraat+80,+1012+PH+Amsterdam,+Nizozemsko/@52.3706901,4.8914355,21z/data=!4m5!3m4!1s0x47c609c11f22fbf3:0x99f1192a9efc529b!8m2!3d52.3706901!4d4.8915723",
                    linkName: "Show on map",
                    table: [
                        {
                            open: "Monday",
                            time: "11:00—20:00",
                        },
                        {
                            open: "Tuesday",
                            time: "10:00—20:00",
                        },
                        {
                            open: "Wednesday",
                            time: "10:00—20:00",
                        },
                        {
                            open: "Thursday",
                            time: "10:00—21:00",
                        },
                        {
                            open: "Friday",
                            time: "10:00—20:00",
                        },
                        {
                            open: "Saturday",
                            time: "10:00—20:00",
                        },
                        {
                            open: "Sunday",
                            time: "11:00—20:00",
                        },
                    ]
                },
                {
                    id: "2",
                    city: "Amsterdam",
                    position: { lat: 52.37531920523375, lng: 4.894792735582172 },
                    icon: mapMarker,
                    img: Kalverstraat,
                    title: "Kalverstraat 173",
                    link: "https://www.google.com/maps/place/Captain+Candy/@52.3682684,4.8914479,44m/data=!3m1!1e3!4m6!3m5!1s0x47c609edfe471e9b:0xc38f8ad26c080547!8m2!3d52.3682883!4d4.8915916!16s%2Fg%2F11vchk111f?entry=ttu",
                    // link: "https://goo.gl/maps/BG12c4EqxvjW9SGx8",
                    linkName: "Show on map",
                    table: [
                        {
                            open: "Monday",
                            time: "11:00—20:00",
                        },
                        {
                            open: "Tuesday",
                            time: "10:00—20:00",
                        },
                        {
                            open: "Wednesday",
                            time: "10:00—20:00",
                        },
                        {
                            open: "Thursday",
                            time: "10:00—21:00",
                        },
                        {
                            open: "Friday",
                            time: "10:00—20:00",
                        },
                        {
                            open: "Saturday",
                            time: "10:00—20:00",
                        },
                        {
                            open: "Sunday",
                            time: "11:00—20:00",
                        },
                    ]
                },
                {
                    id: "3",
                    position: { lat: 52.37531920523375, lng: 4.894792735582172 },
                    icon: mapMarker,
                    img: amstrdam_2,
                    title: "Nieuwendijk 169",
                    link: "https://goo.gl/maps/BG12c4EqxvjW9SGx8",
                    linkName: "Show on map",
                    table: [
                        {
                            open: "Monday",
                            time: "11:00—20:00",
                        },
                        {
                            open: "Tuesday",
                            time: "10:00—20:00",
                        },
                        {
                            open: "Wednesday",
                            time: "10:00—20:00",
                        },
                        {
                            open: "Thursday",
                            time: "10:00—21:00",
                        },
                        {
                            open: "Friday",
                            time: "10:00—20:00",
                        },
                        {
                            open: "Saturday",
                            time: "10:00—20:00",
                        },
                        {
                            open: "Sunday",
                            time: "11:00—20:00",
                        },
                    ]
                },
            ]
        },
        {
            id: "cologne",
            title: "Our stores in Cologne",
            places: [
                {
                    img: kolin_nad_rynem_1,
                    title: "Hohe Strasse 120-122",
                    link: "https://goo.gl/maps/uXA2JgDoJj8kYCj26",
                    linkName: "Show on map",
                    closet: "Temporarily closed",
                    // table: [
                    //     {
                    //         open: "Monday - Saturday",
                    //         time: "11:00—20:00",
                    //     },
                    //     {
                    //         open: "Sunday",
                    //         time: "closed",
                    //     },
                    // ]
                },
            ]
        },
    ],
it: [
        {
            id: "venecie",
            title: "I nostri porti a Venezia",
            places: [
                {
                    img: benatky_1,
                    title: "Salizada San Giovanni Grisostomo 5803",
                    link: "https://www.google.cz/maps/place/Salizada+S.+Giovanni+Grisostomo,+5803,+30121+Venezia+VE,+It%C3%A1lie/@45.4390141,12.3349899,17z/data=!3m1!4b1!4m5!3m4!1s0x477eb1dbee572bd1:0x87490efe04ea7997!8m2!3d45.4390141!4d12.3371786",
                    linkName: "Show on map",
                    table: [
                        {
                            open: "Monday - Thursday",
                            time: "10:00—22:00",
                        },
                        {
                            open: "Friday - Saturday",
                            time: "10:00—23:00",
                        },
                        {
                            open: "Sunday",
                            time: "10:00—22:00",
                        },
                    ]
                },
                {
                    img: benatky_2,
                    title: "San Marco 5037",
                    link: "https://www.google.cz/maps/place/S.+Marco,+4742-4743,+30100+Venezia+VE,+It%C3%A1lie/@45.4362713,12.3335094,17z/data=!3m1!4b1!4m5!3m4!1s0x477eb1da159153df:0x4a162b15fe2576a6!8m2!3d45.4362676!4d12.3356981",
                    linkName: "Show on map",
                    table: [
                        {
                            open: "Monday - Thursday",
                            time: "10:00—22:00",
                        },
                        {
                            open: "Friday - Saturday",
                            time: "10:00—23:00",
                        },
                        {
                            open: "Sunday",
                            time: "10:00—22:00",
                        },
                    ]
                },
                {
                    img: benatky_3,
                    title: "Castello 5577/5578",
                    link: "https://www.google.cz/maps/place/S.+Marco,+4742-4743,+30100+Venezia+VE,+It%C3%A1lie/@45.4362713,12.3335094,17z/data=!3m1!4b1!4m5!3m4!1s0x477eb1da159153df:0x4a162b15fe2576a6!8m2!3d45.4362676!4d12.3356981",
                    linkName: "Show on map",
                    table: [
                        {
                            open: "Monday - Thursday",
                            time: "10:00—22:00",
                        },
                        {
                            open: "Friday - Saturday",
                            time: "10:00—23:00",
                        },
                        {
                            open: "Sunday",
                            time: "10:00—22:00",
                        },
                    ]
                },
            ]
        },
        {
            id: "prague",
            title: "I nostri porti a Praga",
            places: [
                {
                    img: praha_1,
                    title: "Na Příkopě 10",
                    link: "https://goo.gl/maps/XFnaAuFP8e82",
                    linkName: "Show on map",
                    closet: "Temporaneamente chiuso",
                    // table: [
                    //     {
                    //         open: "Monday - Sunday",
                    //         time: "10:00—21:00",
                    //     },
                    // ]
                },
                {
                    img: praha_2,
                    title: "Karlova 7",
                    link: "https://goo.gl/maps/3c9qQmG18m82",
                    linkName: "Show on map",
                    table: [
                        {
                            open: "Monday - Sunday",
                            time: "10:00—21:00",
                        },
                    ]
                },
            ]
        },
        {
            id: "verona",
            title: "I nostri porti a Verona",
            places: [
                {
                    img: verona_1,
                    title: "Via Cappello 6",
                    link: "https://www.google.cz/maps/place/Via+Cappello,+6,+37121+Verona+VR,+It%C3%A1lie/@45.4420786,10.9979177,21z/data=!4m5!3m4!1s0x477f5f490033de7d:0x547252f32b8cb95d!8m2!3d45.4420523!4d10.9979918",
                    linkName: "Show on map",
                    table: [
                        {
                            open: "Monday - Thursday",
                            time: "10:00—21:00",
                        },
                        {
                            open: "Friday - Saturday",
                            time: "10:00—22:00",
                        },
                        {
                            open: "Sunday",
                            time: "10:00—21:00",
                        },
                    ]
                },
                {
                    img: verona_2,
                    title: "Via Mazzini 78",
                    link: "https://goo.gl/maps/dPopaAr6mCsU2APZ8",
                    linkName: "Show on map",
                    table: [
                        {
                            open: "Monday - Thursday",
                            time: "10:00—21:00",
                        },
                        {
                            open: "Friday - Saturday",
                            time: "10:00—22:00",
                        },
                        {
                            open: "Sunday",
                            time: "10:00—21:00",
                        },
                    ]
                },
            ]
        },
        {
            id: "rome",
            title: "I nostri porti a Rome",
            places: [
                {
                    img: roma,
                    title: "Piazza di Trevi 98",
                    link: "https://www.google.com/maps/place/Piazza+di+Trevi,+98,+00187+Roma+RM,+Italy/@41.9007678,12.4830537,17z/data=!3m1!4b1!4m6!3m5!1s0x132f605327b22a25:0xb5ace38e52288812!8m2!3d41.9007678!4d12.4830537!16s%2Fg%2F11c5pl3jlq?entry=ttu",
                    linkName: "Show on map",
                    table: [
                        {
                            open: "Monday - Thursday",
                            time: "10:00—00:00",
                        },
                        {
                            open: "Friday - Sunday",
                            time: "10:00—01:00",
                        },
                    ]
                },
            ]
        },
        {
            id: "florence",
            title: "I nostri porti a Firenze",
            places: [
                {
                    img: florencie_1,
                    title: "Via Por Santa Maria 38",
                    link: "https://www.google.com/maps/d/u/0/viewer?mid=16L0Iy3_BQDF53QHei8EzXaYBPHI&hl=en_US&ll=43.76910325004388%2C11.254112999999982&z=21",
                    // link: "https://www.google.com/maps/place/Via+dei+Calzaiuoli,+42,+50122+Firenze+FI,+It%C3%A1lie/@43.7707921,11.2554196,17z/data=!3m1!4b1!4m5!3m4!1s0x132a54010999f27b:0xaf2f378a984f75f2!8m2!3d43.7707921!4d11.2554196?hl=cs-CZ",
                    linkName: "Show on map",
                    table: [
                        {
                            open: "Monday - Thursday",
                            time: "10:00—21:00",
                        },
                        {
                            open: "Friday - Saturday",
                            time: "10:00—22:00",
                        },
                        {
                            open: "Sunday",
                            time: "10:00—21:00",
                        },
                    ]
                },
            ]
        },
        {
            id: "amsterdam",
            title: "I nostri porti a Amsterdam",
            places: [
                {
                    id: "1",
                    position: { lat:  52.37069480816792, lng: 4.891576315967946 },
                    icon: mapMarker,
                    img: amstrdam_1,
                    title: "Kalverstraat 80-82",
                    link: "https://www.google.com/maps/place/Kalverstraat+80,+1012+PH+Amsterdam,+Nizozemsko/@52.3706901,4.8914355,21z/data=!4m5!3m4!1s0x47c609c11f22fbf3:0x99f1192a9efc529b!8m2!3d52.3706901!4d4.8915723",
                    linkName: "Show on map",
                    table: [
                        {
                            open: "Monday",
                            time: "11:00—20:00",
                        },
                        {
                            open: "Tuesday",
                            time: "10:00—20:00",
                        },
                        {
                            open: "Wednesday",
                            time: "10:00—20:00",
                        },
                        {
                            open: "Thursday",
                            time: "10:00—21:00",
                        },
                        {
                            open: "Friday",
                            time: "10:00—20:00",
                        },
                        {
                            open: "Saturday",
                            time: "10:00—20:00",
                        },
                        {
                            open: "Sunday",
                            time: "11:00—20:00",
                        },
                    ]
                },
                {
                    id: "2",
                    position: { lat:  52.37069480816792, lng: 4.891576315967946 },
                    icon: mapMarker,
                    img: Kalverstraat,
                    title: "Kalverstraat 173",
                    link: "https://www.google.com/maps/place/Captain+Candy/@52.3682684,4.8914479,44m/data=!3m1!1e3!4m6!3m5!1s0x47c609edfe471e9b:0xc38f8ad26c080547!8m2!3d52.3682883!4d4.8915916!16s%2Fg%2F11vchk111f?entry=ttu",
                    linkName: "Show on map",
                    table: [
                        {
                            open: "Monday",
                            time: "11:00—20:00",
                        },
                        {
                            open: "Tuesday",
                            time: "10:00—20:00",
                        },
                        {
                            open: "Wednesday",
                            time: "10:00—20:00",
                        },
                        {
                            open: "Thursday",
                            time: "10:00—21:00",
                        },
                        {
                            open: "Friday",
                            time: "10:00—20:00",
                        },
                        {
                            open: "Saturday",
                            time: "10:00—20:00",
                        },
                        {
                            open: "Sunday",
                            time: "11:00—20:00",
                        },
                    ]
                },
                {
                    id: "3",
                    position: { lat: 52.37531920523375, lng: 4.894792735582172 },
                    icon: mapMarker,
                    img: amstrdam_2,
                    title: "Nieuwendijk 169",
                    link: "https://goo.gl/maps/BG12c4EqxvjW9SGx8",
                    linkName: "Show on map",
                    table: [
                        {
                            open: "Monday",
                            time: "11:00—20:00",
                        },
                        {
                            open: "Tuesday",
                            time: "10:00—20:00",
                        },
                        {
                            open: "Wednesday",
                            time: "10:00—20:00",
                        },
                        {
                            open: "Thursday",
                            time: "10:00—21:00",
                        },
                        {
                            open: "Friday",
                            time: "10:00—20:00",
                        },
                        {
                            open: "Saturday",
                            time: "10:00—20:00",
                        },
                        {
                            open: "Sunday",
                            time: "11:00—20:00",
                        },
                    ]
                },
            ]
        },
        {
            id: "cologne",
            title: "I nostri porti a Colonia",
            places: [
                {
                    img: kolin_nad_rynem_1,
                    title: "Hohe Strasse 120-122",
                    link: "https://goo.gl/maps/uXA2JgDoJj8kYCj26",
                    linkName: "Show on map",
                    closet: "Temporaneamente chiuso",
                    // table: [
                    //     {
                    //         open: "Monday - Saturday",
                    //         time: "11:00—20:00",
                    //     },
                    //     {
                    //         open: "Sunday",
                    //         time: "closed",
                    //     },
                    // ]
                },
            ]
        },
    ],
};

const cities = {
    en: [
        {
            id: "path-praha",
            scrollId: "prague",
            name: "Praha",
            path: prodejny_map_path_praha,
            img: prodejny_map_path_city_praha,
            imgXs: prodejnyMapPathCityPrahaXs,
        },
        {
            id: "path-verona",
            scrollId: "verona",
            name: "verona",
            path: prodejny_map_path_verona,
            img: prodejny_map_path_city_verona,
            imgXs: prodejny_map_path_city_veronaXs,
        },
        {
            id: "path-amsterdam",
            scrollId: "amsterdam",
            name: "Amsterdam",
            path: prodejny_map_path_amsterdam,
            img: prodejny_map_path_city_amsterdam,
            imgXs: prodejny_map_path_city_amsterdamXs,
        },
        {
            id: "path-benatky",
            scrollId: "venecie",
            name: "Venice",
            path: prodejny_map_path_benatky,
            img: prodejny_map_path_city_benatky,
            imgXs: prodejny_map_path_city_benatkyXs,
        },
        {
            id: "path-florencie",
            scrollId: "florence",
            name: "Florence",
            path: prodejny_map_path_florencie,
            img: prodejny_map_path_city_florencie,
            imgXs: prodejny_map_path_city_florencieXs,
        },
        {
            id: "path-kolin",
            scrollId: "cologne",
            name: "Cologne",
            path: prodejny_map_path_kolin,
            img: prodejny_map_path_city_kolin,
            imgXs: prodejny_map_path_city_kolinXs,
        },
        {
            id: "path-rome",
            scrollId: "rome",
            name: "Roma",
            path: prodejny_map_path_rome,
            img: prodejny_map_path_city_rome,
            imgXs: prodejny_map_path_city_rome_xs,
        },
    ],
    it: [
        {
            id: "path-praha",
            scrollId: "prague",
            name: "Praha",
            path: prodejny_map_path_praha,
            img: prodejny_map_path_city_praha_it,
            imgXs: prodejny_map_path_city_praha_xs_it,
        },
        {
            id: "path-verona",
            scrollId: "verona",
            name: "verona",
            path: prodejny_map_path_verona,
            img: prodejny_map_path_city_verona,
            imgXs: prodejny_map_path_city_veronaXs,
        },
        {
            id: "path-amsterdam",
            scrollId: "amsterdam",
            name: "Amsterdam",
            path: prodejny_map_path_amsterdam,
            img: prodejny_map_path_city_amsterdam,
            imgXs: prodejny_map_path_city_amsterdamXs,
        },
        {
            id: "path-benatky",
            scrollId: "venecie",
            name: "Venice",
            path: prodejny_map_path_benatky,
            img: prodejny_map_path_city_benatky_it,
            imgXs: prodejny_map_path_city_benatky_xs_it,
        },
        {
            id: "path-florencie",
            scrollId: "florence",
            name: "Florence",
            path: prodejny_map_path_florencie,
            img: prodejny_map_path_city_florencie_it,
            imgXs: prodejny_map_path_city_florencie_xs_it,
        },
        {
            id: "path-kolin",
            scrollId: "cologne",
            name: "Cologne",
            path: prodejny_map_path_kolin,
            img: prodejny_map_path_city_kolin_it,
            imgXs: prodejny_map_path_city_kolin_xs,
        },
        {
            id: "path-rome",
            scrollId: "rome",
            name: "Roma",
            path: prodejny_map_path_rome,
            img: prodejny_map_path_city_rome,
            imgXs: prodejny_map_path_city_rome_xs,
        },
    ],
};

export {
    StorsData, cities,
}