import React, { useContext } from 'react';
import { dataProcessing } from '../assets/dummyData/faq';
import Faq from '../components/Faq/Faq';
import Helmet from '../components/Helmet';
import { Context } from '../context/Products';

const PersonalDataProcessingPage = () => {
  const [{ lang }] = useContext(Context);
  return (
    <div id="page-faq" className="page">
        <Helmet
          title="Personal Data Processing | Captain Candy"
          metaDesc="You can find our stores in various cities in Europe. But watch out! Not every wood interior store is a guarantee of Captain Candy quality. Always look for only the original Captain Candy stores, which are a safe bet. You can find a list of stores on our website."
        />
        <Faq dataProcessing={dataProcessing[lang].parag} title={dataProcessing[lang].title} />
    </div>
  )
}

export default PersonalDataProcessingPage;
