import { Fragment } from "react";
import img1 from "../img/products/1-xs-min.png"
import img2 from "../img/products/2-xs-min.png"
import img3 from "../img/products/3-xs-min.png"
import img4 from "../img/products/4-xs-min.png"
import img5 from "../img/products/5-xs-min.png"
import img6 from "../img/products/6-xs-min.png"

const productsItems = {
    headTitle: {
        en: <Fragment>Hundreds of shapes, even more flavours <br className="hidden-xs" /> There are thousands of flavours in the world. Everyone has the chance to discover their favourite!</Fragment>,
        it: <Fragment>Nel mondo ci sono migliaia di sapori. <br class="hidden-xs" />  Ognuno può scoprire il proprio!</Fragment>,
    },
    headSubTitle: {
        en: "We believe everyone deserves to discover sweets they’ll want to devour. This is why we choose the very best ingredients and the most reliable suppliers. Take a peek inside our barrels!",
        it: "Vogliamo che tutti trovino da noi le caramelle che desiderano. Pertanto, anche noi scegliamo i migliori ingredienti e i fornitori più affidabili. Da' un'occhiata alle nostre botti!",
    },
    stores: {
        en: "Our stores",
        it: "I nostri negozi",
    },
    en: [
        {
            id: "tangy-jelly",
            title: "Tangy jelly",
            description: "Not every pirate will admit to having a sweet tooth. This seriously tangy jelly is different!  Treat yourself to your favourite sweet while holding onto your hard-core image with its rock hard outside and sweet soft centre.",
            img: img1,
        },
        {
            id: "sweet-jelly",
            title: "Sweet jelly",
            description: "Banana, strawberry, bear or a dual-coloured skull, take your pick. This is the king among sweets. On a number of islands we visited, the wealth of the future bride is judged by the size of her jelly dowry!",
            img: img2,
        },
        {
            id: "batons",
            title: "Batons",
            description: "The baton forms an integral part of all pirate expeditions. Not only does it taste great and have fantastic lasting power, but if necessary it can also double up as a sailing rope and can come in handy for tying up hostages.",
            img: img3,
        },
        {
            id: "marshmallow",
            title:<Fragment>Marsh-<br />mallow</Fragment>,
            description: "If only our ship was made out of marshmallow! It would either reflect or soak up any cannon balls thrown at us by our enemy. We’ve already tried to build a small sloop out of it, however most of the material mysteriously evaporated…",
            img: img4,
        },
        {
            id: "chocolate",
            title: "Chocolate",
            description: "While every proper pirate is tough on the outside, but soft on the inside, you’ll find the exact opposite with these chocolate sweets. Beneath the softest chocolate shell we find crunchy almonds, hazelnuts, chunks of coconut and much more!",
            img: img5,
        },
        {
            id: "chewing-gum",
            title: "Chewing gum",
            description: "When not chewing coca leaves, pirates can be found chewing gum. But not the typical white variety. Even the lowest ranking sailor turns his nose up at that. Pirate chewing gum is colourful and tastes different every time!",
            img: img6,
        },
    ],
    it: [
        {
            id: "pittige-jelly",
            title: "Gelèe acidi",
            description: "Non tutti i pirati ammettono di amare i dolci. Tuttavia, i gelèe acidi sono tutta un'altra cosa! Puoi tranquillamente concederti la tua caramella preferita che nasconde un cuore dolce, mantenendo il tuo aspetto da duro. ",
            img: img1,
        },
        {
            id: "zoete-jelly",
            title: "Gelèe dolci",
            description: "I re delle caramelle a prescindere dalla forma a banana, fragola, orsetto o teschio bicolore. Su diverse isole che abbiamo visitato, la ricchezza della sposa si misura dalla quantità di gelèe in dote!",
            img: img2,
        },
        {
            id: "staafjes",
            title: "Bastoncini di liquirizia",
            description: "I bastoncini di liquirizia fanno parte della dotazione obbligatoria delle spedizioni dei pirati. Non solo hanno un eccellente sapore e durano a lungo, ma all'occorrenza possono anche essere utilizzati come corde nautiche o per legare i prigionieri.",
            img: img3,
        },
        {
            id: "marshmallow",
            title: <Fragment>Marsh-<br/>mallow</Fragment>,
            description: "Sarebbe bellissimo se le nostre navi fossero fatte di marshmallow! Le palle di cannone dei nemici rimbalzerebbero su di loro o ci resterebbero intrappolate. Abbiamo cercato di costruire con i marshmallow almeno una piccola scialuppa, ma la maggior parte del materiale è misteriosamente scomparsa...",
            img: img4,
        },
        {
            id: "chocolade",
            title: "Cioccolatini",
            description: "Mentre ogni buon pirata è un duro esteriormente ma tenero dentro, i nostri cioccolatini sono l'esatto contrario. L'involucro di questi cioccolatini morbidi nasconde mandorle croccanti, noci, pezzi di cocco e molto altro ancora!",
            img: img5,
        },
        {
            id: "kauwgom",
            title: "Gomme da masticare",
            description: "Quando sulla nave pirata non si mastica la coca, si masticano le gomme. Ma non quelle ordinarie, bianche, che non vuole neanche il marinaio più umile. La gomma da masticare per pirati è di grandi dimensioni, colorata e ogni volta ha un gusto diverso!",
            img: img6,
        },
    ],
}

export { productsItems };